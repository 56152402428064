/**
 * 车辆实时监控页面 视频监控
 */
import React, { useState, useRef, useEffect } from 'react';
import { message } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { difference, uniq } from 'lodash';
import { watchCarRequest, unwatchCarRequest } from '@/service/realTimeMonitor';
import { recordSwitchRequest, getVideoDurationRequest } from '@/service/realMonitoring';
import ResizableWrapper from '@/components/resizableWrapper';

import CarTree from '@/components/carTree';
import MultipleWindowLayout from '@/components/multipleWindowLayout';
import LiveVideo from '@/components/liveVideo';
import OverallSetting from './components/overallSetting';
import MapMonitor from './components/mapMonitor';
import AlarmMonitor from './components/alarmMonitor';
import styles from './index.module.scss';

const RealTimeMonitor = () => {
  // 窗口配置
  const windowConfig = [
    { windowCount: 1 },
    { windowCount: 4, gap: 1 },
    { windowCount: 6, gap: 1 },
    { windowCount: 8, gap: 1 },
    { windowCount: 9, gap: 1 },
    { windowCount: 16, gap: 1 }
  ];
  const replaceVideoIndex = useRef(0); // 要播放的通道超出页面播放窗口时替换窗口的index
  const checkCarIdsRef = useRef<number[]>([]); // 勾选的车辆(影响报警监控)
  const selectChannelCarIdsRef = useRef<number[]>([]); // 选择的视频机通道对应的车辆id
  const [selectCarId, setSelectCarId] = useState(null); // 选择的车辆(影响地图上车辆的显示)
  const [checkCarIds, setCheckCarIds] = useState<number[]>([]); // 勾选的车辆(影响报警监控)
  const [selectChannel, setSelectChannel] = useState<any>([]); // 选择的视频机通道
  const [videoMonitorCount, setVideoMonitorCount] = useState(4); // 视频监控窗口数量
  const [settingModal, setSettingModal] = useState(false); // 参数设置弹窗显示控制
  const [selectCarData, setSelectCarData] = useState<{[key: string]: any}>({}); // 选择的车辆(影响地图上车辆的显示)
  const selectChannelList = useRef<any>([])
  // 关闭页面时上报观看记录
  useEffect(() => {
    return () => {
      unwatchCarRequest({ carIds: checkCarIdsRef.current });
      if (selectChannelCarIdsRef.current.length) {
        selectChannelCarIdsRef.current.forEach((item: number) => {
          recordSwitchRequest({
            type: 2, 
            carId: item
          })
        })
      }
    };
  }, []);

  // 上报观看记录
  useEffect(() => {
    const channelCarIds: any = [];
    selectChannel.forEach((item: any) => {
      if (item) {
        channelCarIds.push(item?.carId);
      }
    })
    const uniqChannelCarIds: any = uniq(channelCarIds);
    const addChannelCarIds = difference(uniqChannelCarIds, selectChannelCarIdsRef.current); // 新选择通道的车辆ID
    const deleteChannelCarIds = difference(selectChannelCarIdsRef.current, uniqChannelCarIds); // 取消选择通道的车辆ID
    selectChannelCarIdsRef.current = uniqChannelCarIds;
    if (addChannelCarIds.length) {
      addChannelCarIds.forEach((item: number) => {
        recordSwitchRequest({
          type: 1, 
          carId: item
        })
      })
    }
    if (deleteChannelCarIds.length) {
      deleteChannelCarIds.forEach((item: number) => {
        recordSwitchRequest({
          type: 2, 
          carId: item
        })
      })
    }
  }, [selectChannel])

  // 勾选的车辆
  const treeCheck = (carIds: number[]) => {
    const addCheckCarIds = difference(carIds, checkCarIds); // 新勾选的车辆ID
    const deleteCheckCarIds = difference(checkCarIds, carIds); // 取消勾选的车辆ID
    setCheckCarIds(carIds);
    checkCarIdsRef.current = carIds;
    // 添加车辆进行报警监控
    if (addCheckCarIds?.length) {
      watchCarRequest({ carIds: addCheckCarIds });
    }
    // 删除车辆进行报警监控
    if (deleteCheckCarIds?.length) {
      unwatchCarRequest({ carIds: deleteCheckCarIds });
    }
  };

  // // 添加视频播放窗口
  // const addVideoChannel = (videoChannel: any) => {
  //   console.log(selectChannelList.current, 'selectChannelselectChannelselectChannel')
  //   const newSelectChannel = [...selectChannel];
  //   const emptyChannelIndexs: number[] = []; // 通道数据为null的索引
  //   selectChannel.forEach((item: any, index: number) => {
  //     if (item === null) {
  //       emptyChannelIndexs.push(index);
  //     }
  //   });
  //   const lastSelectChannelIndex = selectChannel.length - 1; // 选择的视频机通道数据最后一项的索引
  //   if (emptyChannelIndexs.includes(lastSelectChannelIndex)) {
  //     // 最后一个窗口通道数据为null时直接替换
  //     newSelectChannel[lastSelectChannelIndex] = videoChannel;
  //   } else if (selectChannel.length >= videoMonitorCount) {
  //     // 要播放的通道超出页面播放窗口时
  //     if (emptyChannelIndexs.length) {
  //       // 替换第一个通道数据为null的窗口
  //       newSelectChannel[emptyChannelIndexs[0]] = videoChannel;
  //     } else {
  //       // 从开始替换窗口
  //       newSelectChannel[replaceVideoIndex.current] = videoChannel;
  //       if (replaceVideoIndex.current + 1 === videoMonitorCount) {
  //         replaceVideoIndex.current = 0;
  //       } else {
  //         replaceVideoIndex.current += 1;
  //       }
  //     }
  //   } else {
  //     // 数组后面添加新选择的通道
  //     newSelectChannel.push(videoChannel);
  //   }
  //   setSelectChannel(newSelectChannel);
  // };

    // 添加视频播放窗口
    const addVideoChannel = (videoChannel: any) => {
      const newSelectChannel = [...selectChannelList.current];
      const emptyChannelIndexs: number[] = []; // 通道数据为null的索引
      newSelectChannel.forEach((item: any, index: number) => {
        if (item === null) {
          emptyChannelIndexs.push(index);
        }
      });
      const lastSelectChannelIndex = selectChannel.length - 1; // 选择的视频机通道数据最后一项的索引
      if (emptyChannelIndexs.includes(lastSelectChannelIndex)) {
        // 最后一个窗口通道数据为null时直接替换
        // newSelectChannel[lastSelectChannelIndex] = videoChannel;
        if (emptyChannelIndexs.length) {
          // 替换第一个通道数据为null的窗口
          newSelectChannel[emptyChannelIndexs[0]] = videoChannel;
        }
      } else if (selectChannel.length >= videoMonitorCount) {
        // 要播放的通道超出页面播放窗口时
        if (emptyChannelIndexs.length) {
          // 替换第一个通道数据为null的窗口
          newSelectChannel[emptyChannelIndexs[0]] = videoChannel;
        } else {
          // 从开始替换窗口
          newSelectChannel[replaceVideoIndex.current] = videoChannel;
          if (replaceVideoIndex.current + 1 === videoMonitorCount) {
            replaceVideoIndex.current = 0;
          } else {
            replaceVideoIndex.current += 1;
          }
        }
      } else {
        // 数组后面添加新选择的通道
        newSelectChannel.push(videoChannel);
      }
      selectChannelList.current = newSelectChannel
      setSelectChannel([...selectChannelList.current])
    };

  // 车辆选择
  const treeSelect = async (selectCarInfo: any) => {
    const {
      type,
      metaId,
      channel,
      jt808DeviceId,
      terminalPhone,
      version,
      plateNumber,
      carId
    } = selectCarInfo;
    // 选择车辆
    if (type === 'car') {
      setSelectCarId(metaId);
      setSelectCarData(selectCarInfo)
    }
    // 选择通道
    if (type === 'channel') {
      // 选择的通道已经在视频窗口时给提示
      if (
        selectChannel.find(
          (item: any) =>
            item?.channel === channel &&
            item?.jt808DeviceId === jt808DeviceId &&
            item?.terminalPhone === terminalPhone
        )
      ) {
        message.warning('所选通道已在视频窗口');
        return;
      }
      // 判断是否有视频观看剩余时间
      const res = await getVideoDurationRequest();
      if (res.data?.data > 0) {
        const videoChannel = {
          channel,
          jt808DeviceId,
          terminalPhone,
          version,
          plateNumber,
          useInRealTimeMonitor: true,
          videoConfig: { videoFillMode: 'fill' },
          carId,
        };
        addVideoChannel(videoChannel);
      } else {
        message.info("该账号观看视频剩余时长不足,请联系:400-990-9171");
      }
    }
  };

  const playAll = async (carInfo: any) => {
    if (carInfo.children) {
       // 判断是否有视频观看剩余时间
       const res = await getVideoDurationRequest();

       if (res.data?.data > 0) {
        if (videoMonitorCount < carInfo.children?.length) {
          changeWindowLayout(6)
        }
        setTimeout(() => {
          carInfo.children?.forEach((channelItem: any, index:number) => {
            console.log(channelItem, '12313')
            const {
              type,
              metaId,
              channel,
              jt808DeviceId,
              terminalPhone,
              version,
              plateNumber,
              carId
            } = channelItem;
            if (type === 'channel') {
              // 选择的通道已经在视频窗口时给提示
              if (
                selectChannelList.current.find(
                  (item: any) =>
                    item?.channel === channel &&
                    item?.jt808DeviceId === jt808DeviceId &&
                    item?.terminalPhone === terminalPhone
                )
              ) {
                message.warning('所选通道已在视频窗口');
                return;
              }
              if (res.data?.data > 0) {
                const videoChannel = {
                  channel,
                  jt808DeviceId,
                  terminalPhone,
                  version,
                  plateNumber,
                  useInRealTimeMonitor: true,
                  videoConfig: { videoFillMode: 'fill' },
                  carId,
                };
                setTimeout(() => {
                  addVideoChannel(videoChannel);
                }, 1000 * index )
                
              } 
            }
  
          })
        }, 1000)

       } else {
        message.info("该账号观看视频剩余时长不足,请联系:400-990-9171");
      }
    }
  }

  // 渲染参数设置入口
  const renderSetting = () => (
    <SettingOutlined
      style={{ cursor: 'pointer' }}
      onClick={() => setSettingModal(true)}
      title="参数设置"
    />
  );

  // 改变窗口布局
  const changeWindowLayout = (count: number) => {
    if (count < videoMonitorCount) {
      const newSelectChannel = [...selectChannel];
      newSelectChannel.length = count;
      selectChannelList.current = newSelectChannel
      setSelectChannel([...selectChannelList.current]);
      
    }
    replaceVideoIndex.current = 0;
    setVideoMonitorCount(count);
  };

  // 关闭视频播放窗口
  const closeWindow = (index: number) => {
    const newSelectChannel = [...selectChannel];
    newSelectChannel[index] = null;
    console.log(newSelectChannel, '123123123')
    selectChannelList.current = newSelectChannel
    setSelectChannel([...selectChannelList.current]);
    
  };

  return (
    <div className={styles.realTimeMonitor}>
      <ResizableWrapper
        resizeHandleDirection="right"
        defaultSizeStyle={{ width: 202, height: '100%' }}
        minConstraints={[202, 10]}
        maxConstraints={[400, 10]}
      >
        <div className={styles.carTreeWrapper}>
          <CarTree
            checkable
            selectableTypes={['car', 'channel']}
            treeCheck={treeCheck}
            treeSelect={treeSelect}
            extraTitle={renderSetting()}
            showChannel
            showFilter
            defaultFilterKey="hasJtDevice"
            clickReplaceSelect
            isLive={true}
            livePlayCallBack={playAll}
          />
        </div>
      </ResizableWrapper>
      <div className={styles.monitorContent}>
        <div className={styles.videoMapMonitor}>
          <div className={styles.videoMonitorWrapper}>
            {/* 切换视频窗口区域 */}
            <div className={styles.switchVideoLayout}>
              {windowConfig.map((item: any) => (
                <MultipleWindowLayout
                  {...item}
                  className={styles.blockItem}
                  onClick={changeWindowLayout}
                  key={item.windowCount}
                >
                  <div className={styles.blockChildrenItem} />
                </MultipleWindowLayout>
              ))}
            </div>
            {/* 展示视频窗口区域 */}
            <MultipleWindowLayout
              windowCount={videoMonitorCount}
              windowAllData={selectChannel}
              gap={4}
              className={styles.videoMonitor}
              closeWindow={closeWindow}
            >
              <LiveVideo />
            </MultipleWindowLayout>
          </div>
          <MapMonitor carId={selectCarId} carData={selectCarData} />
        </div>
        <AlarmMonitor
          onOpenCallback={() => watchCarRequest({ carIds: checkCarIds })}
        />
      </div>
      {/* 参数设置弹窗 */}
      {settingModal && (
        <OverallSetting closeModal={() => setSettingModal(false)} />
      )}
    </div>
  );
};

export default RealTimeMonitor;
