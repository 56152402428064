/**
 * 报警详细信息弹窗
 */
import React, {Fragment} from 'react';
import {isNil} from 'lodash';
import {Modal} from 'antd';
import CommonTable from '@/components/commonTable';
import {alarmKeyData} from '@/constants/alarm';
import {formatTime} from '@/utils/formatTime';
import {formatGpsSpeed} from '@/utils/gps';

interface AlarmInfoModalProps {
  data: any; // 数据
  closeModal?: () => void; // 关闭弹窗的方法
  version?: string; // 传入版本时使用传入的版本进行判断
  infoKey?: string; // 报警信息取值使用的字段
  onlyTable?: boolean; // 只显示表格不显示弹窗
  scroll?: any; // 表格的滚动参数
}

const AlarmInfoModal = (props: AlarmInfoModalProps) => {
  const {
    data,
    closeModal,
    version,
    infoKey = 'alarm_info',
    onlyTable,
    scroll = {}
  } = props;
  const columns: any = [
    {
      title: '名称',
      dataIndex: 'title'
    },
    {
      title: '报警起始数据',
      dataIndex: 'value'
    },
    {
      title: '报警终止数据',
      dataIndex: 'endValue'
    }
  ]; // 表格列显示
  const isOldVersion = (version || data.version) === '1.0'; // 是否是黄河1.0版本
  // 黄河1.0版本不显示结束数据
  if (isOldVersion) {
    columns.splice(2, 1);
  }
  const tableData: any = []; // 表格数据
  const alarmInfo = data[infoKey] || {}; // 报警详细信息
  delete alarmInfo.isBegin; // 删除报警起止行改为在表头中显示
  Object.keys(alarmInfo).forEach((alarmInfoKey: any) => {
    let alarmInfoValue = alarmInfo[alarmInfoKey];

    //跳过非数组
    if (!Array.isArray(alarmInfoValue)) {
      // alarmInfoValue = [alarmInfoValue];
      return
    }
    if (alarmInfoValue.some((item: any) => !isNil(item))) {
      // 格式化显示时间相关参数
      if (['gpsDateTime', 'time'].includes(alarmInfoKey)) {
        alarmInfoValue = alarmInfoValue.map((time: string) => formatTime(time));
      }
      // 格式化显示GPS速度
      if (alarmInfoKey === 'gpsSpeed') {
        alarmInfoValue = alarmInfoValue.map((item: string | number) =>
          formatGpsSpeed(item)
        );
      }
      // 根据alarmKeyData显示报警详细信息
      const displayAlarmInfoValue = alarmKeyData[alarmInfoKey]; // 要展示的数据
      tableData.push({
        title: displayAlarmInfoValue?.name || alarmInfoKey,
        value:
          displayAlarmInfoValue?.value?.[alarmInfoValue[0]] ||
          alarmInfoValue[0],
        endValue:
          displayAlarmInfoValue?.value?.[alarmInfoValue[1]] || alarmInfoValue[1]
      });
    }
  });

  // 渲染报警表格
  const renderAlarmTable = () => (
    <CommonTable
      rowKey="title"
      columnFilter={false}
      size="small"
      columns={columns}
      dataSource={tableData}
      pagination={false}
      bordered
      scroll={scroll}
    />
  );

  if (onlyTable) {
    return <Fragment>{renderAlarmTable()}</Fragment>;
  } else {
    return (
      <Modal
        title="报警详细信息"
        open
        footer={null}
        onCancel={closeModal}
        bodyStyle={{maxHeight: '70vh', overflow: 'auto'}}
        centered
        width="50%"
      >
        {renderAlarmTable()}
      </Modal>
    );
  }
};

export default AlarmInfoModal;
