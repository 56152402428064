/**
 * 部标机设备列表
 */
import React, {useState} from 'react';
import {
  message,
  Popconfirm,
  Card,
  Form,
  Row,
  Col,
  Input,
  Button,
  Select
} from 'antd';
import CommonTable from '@/components/commonTable';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import PermissionButton from '@/components/permissionButton';
import {useTable} from '@/utils/useTable';
import {
  jtDeviceListRequest,
  deleteJtDeviceRequest,
  unbindJtDeviceRequest, jtDeviceListRequestPath
} from '@/service/jtDevice';
import {formSearchGutter} from '@/constants/common';
import {jt808Version} from '@/constants/jtDevice';
import OperateModal from './components/operateModal';
import ParamsConfigModal from './components/paramsConfigModal';
import styles from '@/styles/pageContent.module.scss';
import dayjs from 'dayjs';
import {values} from "lodash";
import ExportButton from "@/components/exportButton";
import {deviceAdasAggRequestPath, deviceAggRequestPath} from "@/service/abnormalSummary";

// 厂家的编码和名称的映射，后续如果用的多的话考虑单拿出来放到公共定义里
const ManufacturerMap: { [key: string]: string } = {
  '1': '博实结',
  '2': '华宝',
  '3': '易联星拓',
  '4': '珠海骏驰',
  '5': '三一海星',
  '6': '鼎奕科技'
}
const ManufacturerOptions = Object.keys(ManufacturerMap).map(key => ({label: ManufacturerMap[key], value: key}));
const ExportPermissionKey = '/car-admin/dvr-device/export';
const JtDevice = () => {
  const [form] = Form.useForm();
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息
  const [searchParams, setSearchParams] = useState<any>({}); // 搜索相关数据

  const {page, limit, loading, data, totalCount, onPageChange, getData} =
    useTable({
      requestFunc: jtDeviceListRequest,
      searchParams,
      initRequest: true,
      initPageSize: 40
    });

  // 删除部标机设备
  const deleteJtDevice = async (id: number) => {
    const res = await deleteJtDeviceRequest(id);
    if (res.data?.data) {
      message.success('删除成功');
      getData();
    }
  };

  // 解绑部标机设备
  const unbindJtDevice = async (plateNumber: string) => {
    const res = await unbindJtDeviceRequest(plateNumber);
    if (res.data?.data) {
      message.success('解绑成功');
      getData();
    }
  };
  //处理表格的参数
  const prePrecessParams = (values: any) => {

    const params: any = {};
    const {plateNumber, phone, version, isOnline, isLoggedIn, manufacturer, sim} = values;
    if (plateNumber) {
      params.plateNumber = plateNumber.trim();
    }
    if (phone) {
      params.phone = phone.trim();
    }
    if (version) {
      params.version = version;
    }
    if (isOnline !== undefined) {
      params.isOnline = isOnline;
    }
    if (isLoggedIn !== undefined) {
      params.isLoggedIn = isLoggedIn;
    }
    if (sim?.trim()){
      params.sim = sim
    }


    // 厂家
    //这里的接口要求是int，但是因为是get，以后有改动这里也要改
    if (manufacturer) {
      params.manufacturer = manufacturer;
    }

    return params
  }
  // 搜索
  const onSearch = async (values: any) => {
    const params = prePrecessParams(values);
    setSearchParams(params);
  };

  //导出excel
  const getExportExcelParams = async () => {
    const searchParams = prePrecessParams(form.getFieldsValue());
    //导出和查询用的同一个接口，只要把export字段设置为true就可以直接返回文件
    return {...searchParams, export: true};

  }

  // 表格列显示
  const columns = [
    {
      title: '终端手机号',
      dataIndex: 'phone',
      width: 110,
      ellipsis: {showTitle: false},
      render: (value: string) => <RenderEllipsisColumn text={value}/>
    },
    {
      title: '终端ID',
      dataIndex: 'terminalId',
      width: 110,
      ellipsis: {showTitle: false},
      render: (value: string) => <RenderEllipsisColumn text={value}/>
    },
    {
      title: 'SIM卡ICCID',
      dataIndex: 'sim',
      width: 110,
      ellipsis: {showTitle: false},
      render: (value: string) => <RenderEllipsisColumn text={value}/>
    },
    {
      title: '厂家',
      dataIndex: 'manufacturer',
      width: 110,
      ellipsis: {showTitle: false},
      render: (value: number) => (
        <RenderEllipsisColumn
          text={ManufacturerMap[`${value}`] || ''}
        />
      )
    },
    {
      title: '808协议版本',
      dataIndex: 'version',
      width: 110,
      ellipsis: {showTitle: false},
      render: (value: string) => <RenderEllipsisColumn text={value}/>
    },
    {
      title: '视频通道',
      dataIndex: 'channelNos',
      width: 110,
      ellipsis: {showTitle: false},
      render: (value: string) => <RenderEllipsisColumn text={value}/>
    },
    {
      title: '绑定车辆',
      dataIndex: 'plateNumber',
      width: 110,
      ellipsis: {showTitle: false},
      render: (value: string) => <RenderEllipsisColumn text={value}/>
    },
    //20240823 guyu 更改字段
    // 是否在线 字段由onlineFlag更改isOnline
    {
      title: '是否在线',
      dataIndex: 'onlineFlag',
      width: 110,
      ellipsis: {showTitle: false},
      render: (value: number) => <RenderEllipsisColumn text={value ? '在线' : '未在线'}/>,
    },
    {
      title: '是否上线',
      dataIndex: 'isLoggedIn',
      width: 110,
      ellipsis: {showTitle: false},
      render: (value: number) => <RenderEllipsisColumn text={value ? '上线' : '未上线'}/>,
    },
    {
      title: '最新上线时间',
      dataIndex: 'onlineTime',
      width: 110,
      ellipsis: {showTitle: false},
      render: (value: string) => value ? <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD HH:mm')}/> : ''
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 160,
      fixed: 'right' as const,
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType="/car-admin/dvr-device/edit"
              type="link"
              onClick={() => setOperateInfo({type: 'edit', data: item.id})}
            >
              编辑
            </PermissionButton>
            <PermissionButton
              operateType="/car-admin/dvr-device/config"
              type="link"
              onClick={() =>
                setOperateInfo({type: 'config', data: item.phone})
              }
            >
              参数配置
            </PermissionButton>
            {item.plateNumber && (
              <Popconfirm
                title="您确定要解绑该部标机设备吗?"
                onConfirm={() => unbindJtDevice(item.plateNumber)}
              >
                <PermissionButton
                  operateType="/car-admin/dvr-device/unbind"
                  type="link"
                >
                  解绑
                </PermissionButton>
              </Popconfirm>
            )}
            <Popconfirm
              title="您确定要删除该部标机设备吗?"
              onConfirm={() => deleteJtDevice(item.id)}
            >
              <PermissionButton
                operateType="/car-admin/dvr-device/delete"
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
          </div>
        );
      }
    }
  ];
  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form} autoComplete="off">
        <Row gutter={formSearchGutter} wrap>
          <Col span={4}>
            <Form.Item label="车牌号" name="plateNumber">
              <Input placeholder="请输入车牌号" allowClear onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="终端手机号" name="phone">
              <Input placeholder="请输入终端手机号" allowClear/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="SIM卡ICCID" name="sim">
              <Input placeholder="请输入sim卡ICCID" allowClear/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="808协议版本" name="version">
              <Select
                options={jt808Version}
                placeholder="请选择808协议版本"
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label={'厂家'} name={'manufacturer'}>
              <Select options={ManufacturerOptions} placeholder={'请选择厂家'} allowClear></Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label={'是否在线'} name={'isOnline'}>
              <Select options={[{label: '是', value: true}, {label: '否', value: false}]} allowClear
                      placeholder="请选择是否在线"></Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label={'是否上线'} name={'isLoggedIn'}>
              <Select options={[{label: '是', value: true}, {label: '否', value: false}]} allowClear
                      placeholder="请选择是否上线"></Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">
                  搜索
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType="/car-admin/dvr-device/add"
          type="primary"
          onClick={() => setOperateInfo({type: 'add'})}
        >
          添加
        </PermissionButton>

        {/*<Button onClick={exportExcel}>导出</Button>*/}
        <ExportButton
          url={`${jtDeviceListRequestPath}`}
          requestMethod='GET'
          fileName={`部标机设备列表.xlsx`}
          getParamsFunc={getExportExcelParams}
          permissionKey={ExportPermissionKey}
        />
      </div>
      <CommonTable
        rowKey="id"
        reloadFunc={getData}
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange
        }}
        bordered
        loading={loading}
      />
      {/* 部标机操作弹窗 */}
      {['add', 'edit'].includes(operateInfo.type) && (
        <OperateModal
          type={operateInfo.type}
          editId={operateInfo.data}
          onClose={() => setOperateInfo({})}
          reloadData={() => getData()}
        />
      )}
      {/* 部标机参数配置弹窗 */}
      {operateInfo.type === 'config' && (
        <ParamsConfigModal
          phone={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      )}
    </Card>
  );
};

export default JtDevice;
