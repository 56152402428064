import {
  Button,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  addPremiumRequest,
  importPremiumRequestPath,
} from "@/service/offerRecord";
import useVehicleType from "@/pages/offerRecord/utils/vehicleType.hook";
import moment from "moment";
import useAllCities from "@/pages/offerRecord/utils/allCities.hook";
import useFuelType from "@/pages/offerRecord/utils/fuelType.hook";
import UploadExcel from "@/components/uploadExcel";
import UploadImageVideo from "@/components/uploadImageVideo";
import { set } from "lodash";
import { file } from "jszip";
import useOperator from "@/pages/offerRecord/utils/operator.hook";
import { channelListRequest } from "@/service/channelRecode";
import dayjs from "dayjs";
import { InfoCircleOutlined } from "@ant-design/icons";

const AllVehicleFlag = [
  { label: "机动车", value: 1 },
  { label: "特种车", value: 2 },
  { label: "新能源车", value: 3 },
  { label: "未知", value: 0 },
];

enum QuotationRecordModalType {
  Add = 1,
  // Edit = 2,
  Copy = 3,
}

interface QuotationRecordModalProps {
  open: boolean;
  onCancel: () => void;
  modalType: QuotationRecordModalType;
  columns: any[];

  data?: any; //用于回显的数据
}

function QuotationRecordModal(props: QuotationRecordModalProps) {
  const { open, onCancel, modalType, columns, data } = props;
  const [sanDianCheck, setSanDianCheck] = useState<null | boolean>(null);
  const [fuleType, setFuleType] = useState("");
  const [carAge, setCarAge] = useState(0)
  const [form] = Form.useForm();

  const title =
    modalType === QuotationRecordModalType.Add
      ? "新增报价记录"
      : "复制报价记录";
  const formItems = columns
    .slice(0, columns.length - 2) //去掉最后两个字段(附件和操作列)
    .filter((item: any) => {
      //去掉area和operator字段
      //area字段因为要和city字段一起使用，所以不单独展示
      //operator字段是当前操作人的id，不需要展示
      return !(item.dataIndex === "area" || item.dataIndex === "operator" || item.dataIndex === 'instalment' || item.dataIndex === 'selfBurningFlag' || item.dataIndex === 'threeElectricityFlag');
    });

    formItems.push({dataIndex : 'cityCode', title: '城市'})

  const { allVehicleType } = useVehicleType();
  const { allCities } = useAllCities();
  const { fuelType } = useFuelType();
  // const {operatorList} = useOperator();
  const [fileList, setFileList] = useState<any>([]);
  const [allChannels, setAllChannels] = useState<any>([]);

  //获取所有渠道
  const getAllChannels = async () => {
    const res = await channelListRequest({ page: 1, limit: 50000, name: "" });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { name, id } = item;
      tags.push({ label: name, value: name });
    });
    setAllChannels(tags);
  };

  //根据传过来的原数据设置表单值
  const setFormValues = (pastData: any) => {
    if (!pastData) {
      return;
    }
    const pastParams = { ...pastData };

    //处理数据
    if (pastParams.id) {
      delete pastParams.id;
    }
    if (pastParams.createdAt) {
      pastParams.createdAt = moment(pastParams.createdAt);
    }
    if (pastParams.registerDate) {
      pastParams.registerDate = moment(pastParams.registerDate);
    }
    // if (pastParams.operator) {
    //   const operatorObj = operatorList.find((item: any) => item.label === pastParams.operator) || {};
    //   pastParams.operator = operatorObj.id;
    //   // delete pastParams.operator;
    // }

    if (pastParams.vehicleType) {
      let hit = false;
      for (const item of allVehicleType) {
        if (item.children) {
          for (const child of item.children) {
            if (child.label === pastParams.vehicleType) {
              pastParams.vehicleType = [item.value, child.value];
              hit = true;
              break;
            }
          }
        }
      }
      //应该是用label匹配的，但是有的老数据是value，这里做一次兼容
      if (!hit) {
        for (const item of allVehicleType) {
          if (item.children) {
            for (const child of item.children) {
              if (child.value === pastParams.vehicleType) {
                pastParams.vehicleType = [item.value, child.value];
                hit = true;
                break;
              }
            }
          }
        }
      }
    }


    //处理附件字段
    if (pastParams.attacheList) {
      setFileList(
        pastParams.attacheList.map((item: any) => {
          return {
            preview: item.url,
            ...item,
          };
        })
      );
      delete pastParams.attacheList;
    } else {
      setFileList([]);
    }

    //不存在只选area不选city的可能，所以这里直接判断area和city是否全存在
    if (pastParams.area && pastParams.city) {
      const areaObj =
        allCities.find((item: any) => item.label === pastParams.area) || {};
      const cityObjectList = areaObj.children || [];
      const cityObj =
        cityObjectList.find((item: any) => item.label === pastParams.city) ||
        {};

      pastParams.cityCode = [areaObj.value, cityObj.value]; //借用city字段存储省市的value
      console.log(pastParams, '12313123123123123123')
      delete pastParams.area;
      delete pastParams.city
    }
    pastParams.createdAt = moment(moment().format("YYYY-MM-DD"));

    setFuleType(pastParams.fuelType);
    setSanDianCheck(pastParams.threeElectricityFlag);

    if (pastParams.threeElectricityFlag) {
      pastParams.threeElectricityFlag = ['是']
    } else {
      pastParams.threeElectricityFlag = []
    }

    if (pastParams.selfBurningFlag) {
      pastParams.selfBurningFlag = ['是']
    } else {
      pastParams.selfBurningFlag = []
    }

    pastParams.holderIdCard = pastData.idCard

    form.setFieldsValue({
      ...pastParams,
    });
  };
  //提交
  const submit = async () => {
    //检查字段：车牌号不能为空
    const licenseNo = form.getFieldValue("licenseNo") || "";
    if (!licenseNo.trim()) {
      message.error("车牌号不能为空");
      return;
    }

    const values = form.getFieldsValue();

    if (values.cityCode?.[1]) {
      values.cityCode = values.cityCode[1];
      delete values.city;
    }

    
    if (values.carOwnerName) {
      values.carOwnersName = values.carOwnerName;
      delete values.carOwnerName;
    }
    // if (values.operator) {
    //   values.userId = values.operator;
    //   delete values.operator;
    // }

    if (values?.vehicleType?.[1]) {
      const parentNode = allVehicleType.find(
        (item: any) => item.value === values.vehicleType[0]
      );
      if (parentNode?.children?.length) {
        const currentNode = parentNode.children.find(
          (item: any) => item.value === values.vehicleType[1]
        );
        if (currentNode) {
          values.vehicleType = currentNode.label;
        }
      }
    } else {
      delete values.vehicleType;
    }

    if (values?.createdAt) {
      values.createdAt = values.createdAt.format("YYYY-MM-DD");
    }
    if (values?.registerDate) {
      values.registerDate = values.registerDate.format("YYYY-MM-DD");
    }
    if (fileList.length > 0) {
      values.attaches = fileList.map((item: any, idx: number) => {
        return {
          contentType: item.contentType,
          key: item.key,
          fileName: item.fileName,
          order: idx,
        };
      });
    }

    const userDetailJsonStr = localStorage.getItem("userDetail") || "{}";
    const userDetail = JSON.parse(userDetailJsonStr);
    const { id } = userDetail;
    values.userId = id;
    if (values.threeElectricityFlag?.length) {
      values.threeElectricityFlag = true
    } else {
      values.threeElectricityFlag = false
    }

    if (values.selfBurningFlag.length) {
      values.selfBurningFlag = true
    } else {
      values.selfBurningFlag = false
    }

    const res = await addPremiumRequest(values);
    onCloseModal();
  };
  //关闭时候的回调
  const onCloseModal = () => {
    onCancel();
    setFileList([]);
    form.resetFields();
  };

  const sanDianChange = (e: any) => {
    console.log(e)
    setSanDianCheck(!!e.length);
    form.setFieldValue("threeElectricityFlag", e);
    if (!e.length) {
      form.setFieldValue("selfBurningFlag", [])
    }
    
  };

  const ziRanChange = (e: any) => {
    form.setFieldValue("selfBurningFlag", e);
  };

  const fuelChange = (e: any) => {
    if (e) {
      form.setFieldValue("threeElectricityFlag", []);
      setSanDianCheck(null);
      form.setFieldValue("selfBurningFlag", []);
    }
    setFuleType(e);
  };
  //根据key渲染表单项内的组件
  const renderItemContent = (item: any) => {
    let res;
    switch (item.dataIndex) {
      case 'instalment':
      case 'threeElectricityFlag':
      case 'selfBurningFlag':
      res = null;
      break;
      case "carOwnerDepartment":
        res = <Select options={allChannels} showSearch allowClear={true} />;
        break;
      case "vehicleFlag":
        res = <Select options={AllVehicleFlag} allowClear={true} />;
        break;
      case "vehicleType":
        res = <Cascader options={allVehicleType} allowClear={true} />;
        break;
      case "fuelType":
        res = <Select options={fuelType} allowClear={true} onChange={fuelChange} />;
        break;
      case "deviceType":
        res = <Select  options={[
          {value: 'AEBS', label: 'AEBS'},
          {value: 'ADAS', label: 'ADAS'}
         ]} allowClear={true}  />;
        break;
      // case "instalment":
      //   res = (
      //     <Radio.Group>
      //       <Radio value={1}>分期</Radio>
      //       <Radio value={0}>不分期</Radio>
      //     </Radio.Group>
      //   );
      //   break;
      // case 'operator':
      // res = <Select options={operatorList} allowClear={true}/>
      // break
      case "createdAt":
      case "registerDate":
        res = <DatePicker onChange={(data) => {
          setCarAge(moment()?.diff(data, 'year'))
          if (moment()?.diff(data, 'year') > 8) {
            form.setFieldValue('selfBurningFlag', [])
          }
        }} />;
        break;
      case "cityCode":
        res = (
          <Cascader
            options={allCities}
            allowClear={true}
            onChange={() => {
              console.log(form.getFieldsValue());
            }}
          />
        );
        break;
      // case "cityCode":
      //   res = (
      //     <Cascader
      //       options={allCities}
      //       allowClear={true}
      //       onChange={() => {
      //         console.log(form.getFieldsValue());
      //       }}
      //     />
      //   );
      //   break;
      case "remark":
        res = <Input.TextArea />;
        break;
      default:
        res = <Input />;
    }

    return res;
  };
  //渲染表单项
  const renderFormItem = (item: any) => {
    return (
      <Col span={7} key={item.dataIndex}>
        <Form.Item
          label={item.title}
          name={item.dataIndex}
          labelCol={{ span: 10 }}
        >
          {renderItemContent(item)}
        </Form.Item>
      </Col>
    );
  };
  //附件上传组件的回调
  const updateFileList = (item: any) => {
    setFileList(item);
  };
  useEffect(() => {
    if (open) {
      // 根据返回的数据设置表单数据
      console.log(data, '11111111111111111111111111')
      setFormValues(data);
    }
  }, [open, data]);

  useEffect(() => {
    getAllChannels();
  }, []);
  return (
    <Modal
      width={"80vw"}
      title={title}
      onCancel={onCloseModal}
      open={open}
      onOk={form.submit}
      destroyOnClose={true}
    >
      <div
        style={{ maxHeight: "70vh", overflowY: "auto", overflowX: "hidden" }}
      >
        <Form form={form} onFinish={submit}>
          <Row gutter={20}>
            {formItems.map(renderFormItem)}
            <Col span={7}>
                <Form.Item
                  required
                  labelCol={{ span: 10 }}
                  label={"是否分期"}
                  name={"instalment"}
                >
                 <Radio.Group>
                    <Radio value={1}>分期</Radio>
                    <Radio value={0}>不分期</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {fuleType === "新能源/电" && (
                <Col span={7}>
                  <Form.Item
                    labelCol={{ span: 10 }}
                    name={"threeElectricityFlag"}
                    label={"含三电"}
                  >
                    <Checkbox.Group
                      defaultValue={[]}
                      onChange={sanDianChange}
                      options={[{ label: "", value: "是" }]}
                    ></Checkbox.Group>
                  </Form.Item>
                </Col>
              )}
              <Col span={7}>
                <Form.Item
                  labelCol={{ span: 10 }}
                  name={"selfBurningFlag"}
                  label={<> <Tooltip placement="top" title={'特殊结构慎选自燃选项，危险品运输车辆拒绝自燃选项'} >
                  <InfoCircleOutlined /><span>含自燃</span>
                </Tooltip></>}
                >
                  <Checkbox.Group
                    defaultValue={[]}
                    onChange={ziRanChange}
                    options={[
                      {
                        label: "",
                        value: "是",
                        disabled: (fuleType === "新能源/电" && !sanDianCheck) || carAge > 8,
                      },
                    ]}
                  ></Checkbox.Group>
                </Form.Item>
              </Col>
            <Col span={7}>
              <Form.Item
                label={"附件"}
                labelCol={{ span: 10 }}
                shouldUpdate={true}
              >
                <UploadImageVideo
                  businessName={"上传附件"}
                  defaultUploadFiles={fileList}
                  uploadFilesChange={updateFileList}
                  canUploadVideo={false}
                  limitSize={10}
                  maxCount={20}
                  multiple={true}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}

export default QuotationRecordModal;
export { QuotationRecordModalType };
