// extracted by mini-css-extract-plugin
export default {"imgBox":"thumbnail_imgBox__p1Lt1","imgItem":"thumbnail_imgItem__1Vt9n","largeImgItem":"thumbnail_largeImgItem__W6ABb","middleImgItem":"thumbnail_middleImgItem__Nzy7Q","smallImgItem":"thumbnail_smallImgItem__nyZy8"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"imgBox\":\"thumbnail_imgBox__p1Lt1\",\"imgItem\":\"thumbnail_imgItem__1Vt9n\",\"largeImgItem\":\"thumbnail_largeImgItem__W6ABb\",\"middleImgItem\":\"thumbnail_middleImgItem__Nzy7Q\",\"smallImgItem\":\"thumbnail_smallImgItem__nyZy8\"}";
        // 1734082449828
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  