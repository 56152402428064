// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","atShowBoxTitle":"atCommand_atShowBoxTitle__BnRD6","atShowBox":"atCommand_atShowBox__TA1Qr","chatBox":"atCommand_chatBox__bYcwx","chatItem":"atCommand_chatItem__50KWh","chat":"atCommand_chat__jYfD4","commandBox":"atCommand_commandBox__uFSVH","tableAtName":"atCommand_tableAtName__HeTb0","atSendForm":"atCommand_atSendForm__B4jv5","buttomFormItem":"atCommand_buttomFormItem__Iq3wY","formContent":"atCommand_formContent__djrZs","tip":"atCommand_tip__tCkpV"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"atShowBoxTitle\":\"atCommand_atShowBoxTitle__BnRD6\",\"atShowBox\":\"atCommand_atShowBox__TA1Qr\",\"chatBox\":\"atCommand_chatBox__bYcwx\",\"chatItem\":\"atCommand_chatItem__50KWh\",\"chat\":\"atCommand_chat__jYfD4\",\"commandBox\":\"atCommand_commandBox__uFSVH\",\"tableAtName\":\"atCommand_tableAtName__HeTb0\",\"atSendForm\":\"atCommand_atSendForm__B4jv5\",\"buttomFormItem\":\"atCommand_buttomFormItem__Iq3wY\",\"formContent\":\"atCommand_formContent__djrZs\",\"tip\":\"atCommand_tip__tCkpV\"}";
        // 1734082437677
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  