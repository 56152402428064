/**
 * 上传视频和图片
 */
import React, {useState, useRef, useEffect} from "react";
import {Upload, message} from "antd";
import {PlusOutlined, LoadingOutlined, PlayCircleOutlined, DeleteOutlined} from '@ant-design/icons';
import localStorageUtil from '@/utils/localStorageUtil';
import ImagePreviewModal from '@/components/imagePreviewModal';
import VideoPreviewModal from '@/components/videoPreviewModal';
import styles from './index.module.scss';

interface UploadImageVideoProps {
  businessName: string; // 使用组件的业务名称
  uploadFilesChange: Function; // 上传文件改变之后的回调方法
  defaultUploadFiles?: Array<{ key: string, preview: string, contentType: string }>; // 默认已经上传成功的文件信息(编辑时使用)
  canUploadVideo?: boolean; // 是否支持上传视频
  limitSize?: number; // 上传内容大小限制(单位M)
  maxCount?: number; // 最多可以上传的个数
  multiple?: boolean; // 是否支持一次上传多张
}

const UploadImageVideo = (props: UploadImageVideoProps) => {
  const {businessName, uploadFilesChange, defaultUploadFiles, canUploadVideo, limitSize, maxCount, multiple} = props;
  const uploadCountRef = useRef(0);
  const [uploadSuccessList, setUploadSuccessList] = useState<any>(defaultUploadFiles || []); // 上传成功的资源信息
  const [uploadLoading, setUploadLoading] = useState<boolean>(false); // 是否上传中
  const [previewUrl, setPreviewUrl] = useState<string>('') // 预览照片的url
  const [previewIdx, setPreviewIdx] = useState<number>(0) // 预览照片的idx(上下翻页用)
  const [videoUrl, setVideoUrl] = useState<string>('') // 视频播放的url

  // 有初始值时调用uploadFilesChange方法传递给使用的组件 
  useEffect(() => {
    if (defaultUploadFiles) {
      uploadFilesChange(defaultUploadFiles);
    }
  }, []);

  // 上传前限制条件(文件类型、文件大小)
  const beforeUpload = (file: any) => {
    let isLimitSize = true;
    if (limitSize) {
      isLimitSize = file.size / 1024 / 1024 < limitSize;
    }
    if (!isLimitSize) {
      message.error('请上传20M以内资源');
    }
    const allowType = ['image'];
    if (canUploadVideo) {
      allowType.push('video');
    }
    const isLimitType = Boolean(allowType.find((item: string) => file.type?.startsWith(item)));
    if (!isLimitType) {
      message.error(canUploadVideo ? '请上传图片或视频' : '请上传图片');
    }
    return isLimitSize && isLimitType;
  };
  // 上传资源改变的方法
  const onUploadChange = (info: any) => {
    // 上传结束的计数+1
    const addUploadCount = () => {
      uploadCountRef.current += 1;
      if (uploadCountRef.current === (info.fileList || []).length) {
        setUploadLoading(false);
      }
    }
    if (info.file.status === 'uploading') {
      setUploadLoading(true);
    }
    if (info.file.status === 'error') {
      addUploadCount();
      message.error('上传失败');
    }
    if (info.file.status === 'done') {
      addUploadCount();
      const code = info.file.response?.code;
      console.log('code', code);
      if (code === 0) {
        const newUploadSuccessList = [...uploadSuccessList, info.file.response.data];
        setUploadSuccessList(newUploadSuccessList);
        uploadFilesChange(newUploadSuccessList);
      } else {
        message.error(info.file.response?.msg || '上传失败');
      }
    }
  }

  // 删除上传的内容
  const deleteUpload = (index: number, e: any) => {
    e.stopPropagation();
    const newUploadSuccessList = [...uploadSuccessList];
    newUploadSuccessList.splice(index, 1);
    setUploadSuccessList(newUploadSuccessList);
    uploadFilesChange(newUploadSuccessList);
  }

  // 预览
  const onPreview = (file: any) => {
    
    if (file.contentType?.startsWith('image')) {
      setPreviewUrl(file?.preview);
      const idx = uploadSuccessList.findIndex((item: any) => item.key === file.key);
      console.log('idx', idx);
      setPreviewIdx(idx);
    } else {
      setVideoUrl(file?.preview)
    }
  };

  let accept = "image/*"; // upload的accept
  let uploadText = '上传图片'; // 上传的文案描述
  if (canUploadVideo) {
    accept = "image/*,video/*";
    uploadText = '上传图片/视频';
  }

  return (
    <div className={styles.uploadImageVideo}>
      <div className={styles.previewBox}>
        {uploadSuccessList.map((item: any, index: number) => (
          <div className={styles.previewItem} key={item.key} onClick={() => onPreview(item)}>
            {item.contentType?.startsWith('image') ?
              <img src={item.preview || item.url} alt={`${businessName}图片`}/> :
              <PlayCircleOutlined/>
            }
            <DeleteOutlined className={styles.deleteIcon} onClick={(e: any) => deleteUpload(index, e)}/>
          </div>
        ))}
      </div>
      {/* 没有限制上传数量或者上传数小于限制时显示上传 */}
      {(!maxCount || uploadSuccessList.length < maxCount) && <Upload
        accept={accept}
        action="/api/v2/resource/upload"
        headers={{access_token: localStorageUtil.getItem('access_token')}}
        beforeUpload={beforeUpload}
        onChange={onUploadChange}
        multiple={multiple}
      >
        <div className={styles.uploadCard}>
          {uploadLoading ? <LoadingOutlined/> : <PlusOutlined/>}
          <div>{uploadText}</div>
        </div>
      </Upload>}
      {/* 预览照片的弹窗 */}
      {Boolean(previewUrl) && <ImagePreviewModal
        url={previewUrl}
        fileName={`${businessName}图片`}
        pubArray={uploadSuccessList.filter((item: any) => item.contentType?.startsWith('image'))}
        pubIndex={previewIdx}
        closeModal={() => setPreviewUrl('')}
        showDownload={false}
      />}
      {/* 视频播放的弹窗 */}
      {Boolean(videoUrl) && <VideoPreviewModal
        url={videoUrl}
        fileName={`${businessName}视频`}
        closeModal={() => setVideoUrl('')}
        showDownload={false}
      />}
    </div>
  )
}

export default UploadImageVideo;