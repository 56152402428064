// extracted by mini-css-extract-plugin
export default {"ultrasonicLocationBox":"sensorUltrasonicLocation_ultrasonicLocationBox__L-HOj","leftBox":"sensorUltrasonicLocation_leftBox__bK794","rightBox":"sensorUltrasonicLocation_rightBox__aX-0w","selectItemOne":"sensorUltrasonicLocation_selectItemOne__DedbB","selectItemTwo":"sensorUltrasonicLocation_selectItemTwo__CPhRn","selectItemThree":"sensorUltrasonicLocation_selectItemThree__gKiSm","selectItemFour":"sensorUltrasonicLocation_selectItemFour__Rkdgi","selectItemFive":"sensorUltrasonicLocation_selectItemFive__B8za8","selectItemSix":"sensorUltrasonicLocation_selectItemSix__oJ+UW","middleBox":"sensorUltrasonicLocation_middleBox__P5kF3","middleImage":"sensorUltrasonicLocation_middleImage__EFmrk","middleTop":"sensorUltrasonicLocation_middleTop__nwQmG","line":"sensorUltrasonicLocation_line__dCuNr","middleBottom":"sensorUltrasonicLocation_middleBottom__Vl5Jq","middleText":"sensorUltrasonicLocation_middleText__rQd19","selectItem":"sensorUltrasonicLocation_selectItem__HG2Tc","selectItemColumn":"sensorUltrasonicLocation_selectItemColumn__j2veJ","displaySelect":"sensorUltrasonicLocation_displaySelect__+fi35","point":"sensorUltrasonicLocation_point__fFQsE"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"ultrasonicLocationBox\":\"sensorUltrasonicLocation_ultrasonicLocationBox__L-HOj\",\"leftBox\":\"sensorUltrasonicLocation_leftBox__bK794\",\"rightBox\":\"sensorUltrasonicLocation_rightBox__aX-0w\",\"selectItemOne\":\"sensorUltrasonicLocation_selectItemOne__DedbB\",\"selectItemTwo\":\"sensorUltrasonicLocation_selectItemTwo__CPhRn\",\"selectItemThree\":\"sensorUltrasonicLocation_selectItemThree__gKiSm\",\"selectItemFour\":\"sensorUltrasonicLocation_selectItemFour__Rkdgi\",\"selectItemFive\":\"sensorUltrasonicLocation_selectItemFive__B8za8\",\"selectItemSix\":\"sensorUltrasonicLocation_selectItemSix__oJ+UW\",\"middleBox\":\"sensorUltrasonicLocation_middleBox__P5kF3\",\"middleImage\":\"sensorUltrasonicLocation_middleImage__EFmrk\",\"middleTop\":\"sensorUltrasonicLocation_middleTop__nwQmG\",\"line\":\"sensorUltrasonicLocation_line__dCuNr\",\"middleBottom\":\"sensorUltrasonicLocation_middleBottom__Vl5Jq\",\"middleText\":\"sensorUltrasonicLocation_middleText__rQd19\",\"selectItem\":\"sensorUltrasonicLocation_selectItem__HG2Tc\",\"selectItemColumn\":\"sensorUltrasonicLocation_selectItemColumn__j2veJ\",\"displaySelect\":\"sensorUltrasonicLocation_displaySelect__+fi35\",\"point\":\"sensorUltrasonicLocation_point__fFQsE\"}";
        // 1734082457964
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  