// extracted by mini-css-extract-plugin
export default {"uploadImageVideo":"uploadFile_uploadImageVideo__CbelR","uploadCard":"uploadFile_uploadCard__oT9cx","previewBox":"uploadFile_previewBox__ht004","previewItem":"uploadFile_previewItem__DAE38","deleteIcon":"uploadFile_deleteIcon__UZBvE"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"uploadImageVideo\":\"uploadFile_uploadImageVideo__CbelR\",\"uploadCard\":\"uploadFile_uploadCard__oT9cx\",\"previewBox\":\"uploadFile_previewBox__ht004\",\"previewItem\":\"uploadFile_previewItem__DAE38\",\"deleteIcon\":\"uploadFile_deleteIcon__UZBvE\"}";
        // 1734082453539
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  