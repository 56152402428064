// extracted by mini-css-extract-plugin
export default {"carDiagnose":"carDiagnose_carDiagnose__wLXHA","searchBox":"carDiagnose_searchBox__rvutW","carInfoBox":"carDiagnose_carInfoBox__6W2nY","carInfoTitle":"carDiagnose_carInfoTitle__d08EC","carInfoContent":"carDiagnose_carInfoContent__v5Hw+","carInfoPerson":"carDiagnose_carInfoPerson__3qb0d","carInfoDriver":"carDiagnose_carInfoDriver__VxRcV","personPhone":"carDiagnose_personPhone__Wef5+","driverPhone":"carDiagnose_driverPhone__3z722","carMap":"carDiagnose_carMap__cpRYo"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"carDiagnose\":\"carDiagnose_carDiagnose__wLXHA\",\"searchBox\":\"carDiagnose_searchBox__rvutW\",\"carInfoBox\":\"carDiagnose_carInfoBox__6W2nY\",\"carInfoTitle\":\"carDiagnose_carInfoTitle__d08EC\",\"carInfoContent\":\"carDiagnose_carInfoContent__v5Hw+\",\"carInfoPerson\":\"carDiagnose_carInfoPerson__3qb0d\",\"carInfoDriver\":\"carDiagnose_carInfoDriver__VxRcV\",\"personPhone\":\"carDiagnose_personPhone__Wef5+\",\"driverPhone\":\"carDiagnose_driverPhone__3z722\",\"carMap\":\"carDiagnose_carMap__cpRYo\"}";
        // 1734082437650
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  