// extracted by mini-css-extract-plugin
export default {"uploadImageVideo":"uploadImageVideo_uploadImageVideo__cSmml","uploadCard":"uploadImageVideo_uploadCard__xa0rE","previewBox":"uploadImageVideo_previewBox__Nvh4g","previewItem":"uploadImageVideo_previewItem__zyi-4","deleteIcon":"uploadImageVideo_deleteIcon__RA0kZ"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"uploadImageVideo\":\"uploadImageVideo_uploadImageVideo__cSmml\",\"uploadCard\":\"uploadImageVideo_uploadCard__xa0rE\",\"previewBox\":\"uploadImageVideo_previewBox__Nvh4g\",\"previewItem\":\"uploadImageVideo_previewItem__zyi-4\",\"deleteIcon\":\"uploadImageVideo_deleteIcon__RA0kZ\"}";
        // 1734082453619
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  