// extracted by mini-css-extract-plugin
export default {"fixPageContentBox":"channelAssessment_fixPageContentBox__tgMjD","topTableItem":"channelAssessment_topTableItem__0Lwbx","topTableItemMain":"channelAssessment_topTableItemMain__woYMH","topTableItemMainThree":"channelAssessment_topTableItemMainThree__lU+c9","topTableItemMainSix":"channelAssessment_topTableItemMainSix__71n1M","topTableItemMainC":"channelAssessment_topTableItemMainC__CoKpu","topTableItemMainP":"channelAssessment_topTableItemMainP__YEh39","topTableItemMainR":"channelAssessment_topTableItemMainR__TMaWO"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"fixPageContentBox\":\"channelAssessment_fixPageContentBox__tgMjD\",\"topTableItem\":\"channelAssessment_topTableItem__0Lwbx\",\"topTableItemMain\":\"channelAssessment_topTableItemMain__woYMH\",\"topTableItemMainThree\":\"channelAssessment_topTableItemMainThree__lU+c9\",\"topTableItemMainSix\":\"channelAssessment_topTableItemMainSix__71n1M\",\"topTableItemMainC\":\"channelAssessment_topTableItemMainC__CoKpu\",\"topTableItemMainP\":\"channelAssessment_topTableItemMainP__YEh39\",\"topTableItemMainR\":\"channelAssessment_topTableItemMainR__TMaWO\"}";
        // 1734082437199
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  