// extracted by mini-css-extract-plugin
export default {"home":"home_home__fPBJp","chartCard":"home_chartCard__6iUVV","title":"home_title__FWgtv","tag":"home_tag__LCd3y","searchBox":"home_searchBox__88Shd","searchButton":"home_searchButton__LU3RD","innerTitle":"home_innerTitle__0bfpz","chart":"home_chart__wQSG3"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"home\":\"home_home__fPBJp\",\"chartCard\":\"home_chartCard__6iUVV\",\"title\":\"home_title__FWgtv\",\"tag\":\"home_tag__LCd3y\",\"searchBox\":\"home_searchBox__88Shd\",\"searchButton\":\"home_searchButton__LU3RD\",\"innerTitle\":\"home_innerTitle__0bfpz\",\"chart\":\"home_chart__wQSG3\"}";
        // 1734082423960
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  