// extracted by mini-css-extract-plugin
export default {"photoBigTitle":"maintenancePictureModal_photoBigTitle__tbbrT","photoItem":"maintenancePictureModal_photoItem__QlQVE","photoItemTitle":"maintenancePictureModal_photoItemTitle__Oqzof","photoItemPhoto":"maintenancePictureModal_photoItemPhoto__PsO5j"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"photoBigTitle\":\"maintenancePictureModal_photoBigTitle__tbbrT\",\"photoItem\":\"maintenancePictureModal_photoItem__QlQVE\",\"photoItemTitle\":\"maintenancePictureModal_photoItemTitle__Oqzof\",\"photoItemPhoto\":\"maintenancePictureModal_photoItemPhoto__PsO5j\"}";
        // 1734082449804
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  