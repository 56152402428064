/**
 * 报警查询
 */
import React, {useState, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Form, Select, Button, message} from 'antd';
import dayjs from 'dayjs';
import {alarmListRequest, alarmListRequestPath, alarmSingleRequest} from '@/service/alarmSearch';
import CarTree from '@/components/carTree';
import DatePicker from '@/components/datePicker';
import AlarmInfoModal from '@/components/alarmInfoModal';
import CommonTable from '@/components/commonTable';
import PositionModal from '@/components/positionModal';
import ExportButton from '@/components/exportButton';
import {useTable} from '@/utils/useTable';
import {alarmTypeData, alarmTypeValue} from '@/constants/alarm';
import {aebAlarmColumns} from '@/columns/aebAlarm';
import styles from '@/styles/pageContent.module.scss';

const {RangePicker} = DatePicker;

const AlarmSearch = () => {
  const tabPagesStore = useSelector((state: any) => state.tabPagesStore);
  const {activeKey, tabParams} = tabPagesStore;
  const [selectCarIds, setSelectCarIds] = useState<Array<number>>([]); // 选择的车辆id
  const [hasSearched, setHasSearched] = useState<boolean>(false); // 是否搜索过
  const [searchParams, setSearchParams] = useState({
    startTime: `${dayjs().format('YYYY-MM-DD')} 00:00:00`,
    endTime: `${dayjs().format('YYYY-MM-DD')} 23:59:59`,
    typeList: alarmTypeValue
  }); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [form] = Form.useForm();
  const {page, limit, loading, data, totalCount, onPageChange} = useTable({
    requestFunc: alarmListRequest,
    initRequest: false,
    initPageSize: 90,
    searchParams,
  });

  const [filterTitleParams, setFilterTitleParams] = useState<any>(); // 过滤行的数据
  // 监听跳转时携带参数的形式
  useEffect(() => {
    if (activeKey === '/car-admin/alarm-search' && tabParams) {
      setSelectCarIds(tabParams.carIds);
      const newTabParams = {
        time: [
          dayjs(`${tabParams.day} 00:00:00`),
          dayjs(`${tabParams.day} 23:59:59`),
        ],
        typeList: [1, 2, 3, 4, 5, 7, 8]
      };
      form.setFieldsValue(newTabParams);
      form.submit();
    }
  }, [activeKey, tabParams]);

  // 处理搜索的参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    if (selectCarIds.length === 0) {
      message.info("请选择车辆");
      return;
    }
    if (params.typeList.length === 0) {
      message.info("请选择报警类型");
      return;
    }
    const newParams = {...params};
    delete newParams.time;
    newParams.startTime = dayjs(params.time[0]).format('YYYY-MM-DD HH:mm:ss');
    newParams.endTime = dayjs(params.time[1]).format('YYYY-MM-DD HH:mm:ss');
    newParams.idList = selectCarIds;
    if (isExport) {
      newParams.export = true;
    }
    return newParams;
  }

  // 搜索
  const onSearch = (values: any) => {
    const newSearchParams: any = operateSearchParams(values);
    if (newSearchParams) {
      setSearchParams(newSearchParams);
      setHasSearched(true);
      getAlarmStatistic(newSearchParams)
    }

  }

  // 获取过滤行的数据
  const getAlarmStatistic = async (params: any) => {
    const data = {...params}
    data.typeList = [1, 2, 3, 4, 5, 6, 7, 8]
    const res = await alarmSingleRequest(data)
    setFilterTitleParams(res.data.data)
  }


  // 点击过滤行按钮的回调
  const clickFilterTitleButton = (value: any) => {
    if (Array.isArray(value) === true) {
      form.setFieldsValue({typeList: [...value]});
    } else {
      form.setFieldsValue({typeList: [value]});
    }
    const params = form.getFieldsValue()
    onSearch(params)
  }

  // 获取导出参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  }

  // 左侧车辆选择
  const treeCheck = (carIds: Array<number>) => {
    setSelectCarIds(carIds)
  }

  // 表格列配置
  const columns = useMemo(() => {
    return [
      ...aebAlarmColumns,
      {
        title: '操作',
        dataIndex: 'operate',
        width: 150,
        fixed: 'right' as 'right',
        render: (value: any, item: any) => {
          return (
            <div className={styles.tableOperateColumn}>
              <Button type="link" onClick={
                () => {
                  setOperateInfo({
                    type: 'info', data: item
                  })
                }
              }>详细信息</Button>
              <Button type="link" onClick={() => setOperateInfo({
                type: 'position', data: {
                  ...item,
                  lat: item.carAlarm.lat,
                  lon: item.carAlarm.lon,

                }
              })}>位置</Button>
            </div>
          )
        },
      },
    ]
  }, []);

  return (
    <div className={styles.spaceBetweenPageContentBox}>
      <div className={styles.carTree}>
        <CarTree
          checkable
          treeCheck={treeCheck}
        />
      </div>
      <div className={styles.contentBox}>
        <div>
          <Form labelCol={{span: 4}} wrapperCol={{span: 20}} onFinish={onSearch} form={form}>
            <Form.Item label="时间范围" name="time"
                       initialValue={[dayjs(searchParams.startTime), dayjs(searchParams.endTime)]}>
              <RangePicker showTime allowClear={false} style={{width: '100%'}}/>
            </Form.Item>
            <Form.Item label="报警类型" name="typeList" initialValue={searchParams.typeList}>
              <Select mode="multiple" options={alarmTypeData} maxTagCount="responsive"/>
            </Form.Item>
            <Form.Item>
              <div className={styles.searchButtonBox}>
                <Button type="primary" htmlType="submit">查询</Button>
                <ExportButton
                  url={alarmListRequestPath}
                  fileName='alarm'
                  getParamsFunc={getExportParams}
                  progress
                  permissionKey='/car-admin/alarm-search/export'
                />
              </div>
            </Form.Item>
          </Form>
        </div>
        {hasSearched ?
          <div className={styles.tableBox}>
            <CommonTable
              rowKey="id"
              columns={columns}
              dataSource={data}
              pagination={{
                total: totalCount,
                current: page,
                pageSize: limit,
                onChange: onPageChange,
              }}
              bordered
              loading={loading}
              filterTitleButton={true}
              filterTitleButtonParams={filterTitleParams == undefined ? [] : filterTitleParams}
              clickFilterTitleButton={(value: any) => clickFilterTitleButton(value)}
            />
          </div> :
          <div className={styles.tipBox}>
            请在左侧选择车辆后点击“查询”按钮
          </div>
        }
      </div>
      {/* 详细信息弹窗 */}
      {operateInfo.type === 'info' &&
        <AlarmInfoModal closeModal={() => setOperateInfo({})} data={operateInfo.data} infoKey="carAlarm"/>
      }
      {/* 位置弹窗 */}
      {operateInfo.type === 'position' &&
        <PositionModal
          closeModal={() => setOperateInfo({})}
          data={operateInfo.data}
          plateNumberField="plateNumber"
        />
      }
    </div>
  )
}

export default AlarmSearch;
