/**
 * 最近位置弹窗
 */
import React, { useRef, useEffect } from 'react';
import gcoord from 'gcoord';
import { Modal, message } from 'antd';
import { copyLocationLink } from '@/utils/location';
import { importMap } from '@/utils/map';

interface PositionModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  plateNumberField?: string; // 车牌号取值的字段
}
const PositionModal = (props: PositionModalProps) => {
  let mapRef: any = useRef(null); // 存储地图实例
  console.log(props, '12313123')
  // const { data, closeModal, plateNumberField = 'plate_number' } = props;
  const { data, closeModal, plateNumberField = 'plateNumber' } = props;

  useEffect(() => {
    // 没有经纬度时给提示
    if (!data.lat || !data.lon) {
      message.info('暂无位置信息');
      closeModal();
      return;
    };
    importMap(initMapCallback);
  }, []);

  // 地图初始化成功的回调
  const initMapCallback = () => {
    mapRef.current = new window.BMap.Map("currentPosition");
    const transformGcoord = gcoord.transform([data.lat, data.lon], gcoord.WGS84, gcoord.BD09);
    const carPoint = new window.BMap.Point(transformGcoord[0], transformGcoord[1]);
    mapRef.current.centerAndZoom(carPoint, 16);
    mapRef.current.enableScrollWheelZoom(); // 开启鼠标滚轮缩放
    mapRef.current.addControl(new window.BMap.MapTypeControl({
      mapTypes:[
        window.BMAP_NORMAL_MAP,
        window.BMAP_HYBRID_MAP
      ]
    })); // 添加卫星图层
    const marker = new window.BMap.Marker(carPoint);
    mapRef.current.addOverlay(marker);
    marker.addEventListener("click", function() {
      showCarInfo(data, carPoint, transformGcoord);
    });
    showCarInfo(data, carPoint, transformGcoord);
  }

  // 点击汽车点位展示汽车信息
  const showCarInfo = async(info: any, carPoint: any, transformGcoord: any) => {
    // 车辆信息的显示
    let carInfoWindow = new window.BMap.InfoWindow("", {
      title: "车辆位置信息"
    });
    const plateNumber = info[plateNumberField] || info.plate_number;
    const locationLink = `http://api.map.baidu.com/marker?location=${transformGcoord[1]},${transformGcoord[0]}&title=${plateNumber}&content=${plateNumber}&output=html`;
    let content = `车牌号：${plateNumber}`;
    content += `<br>地址链接: <a target='_blank' href="${locationLink}">${locationLink}</a><button id='copyLinkButton'>复制</button>`;
    carInfoWindow.setContent(content);
    mapRef.current.openInfoWindow(carInfoWindow, carPoint);
    copyLocationLink(locationLink, carInfoWindow);
  }
  
  // 没有经纬度时不显示弹窗
  if (!data.lat || !data.lon) {
    return null;
  }

  return (
    <Modal
      title="最近位置"
      visible
      footer={null}
      onCancel={closeModal}
      centered
    >
      <div id="currentPosition" style={{ height: '400px' }}></div>
    </Modal>
  )
}

export default PositionModal;
  