/**
 * 操作客服记录
 */
import React, { useState, useEffect } from "react";
import { Modal, Form, Input, message, Select, Switch } from "antd";
import { addServiceRecoed, serviceRecoedRequest, addServiceRecoedBatch } from '@/service/carDiagnosis';
import { tagListRequest } from '@/service/tagManage';
import { TagCategoryEnum } from '@/constants/tagManage';
import UploadImageVideo from "@/components/uploadImageVideo";
import CarSelect from '@/components/carSelect';
import { carListRequest } from '@/service/carManage';
import DatePicker from "@/components/datePicker";

const { TextArea } = Input;

interface OperateMaintenanceProps {
  type: string;
  data?: any; // 车辆信息
  carId?: any;
  editData?: any;
  onClose: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 添加成功后重新请求客服记录的方法
  anyData: (one: any, two: any) => void;
}

const OperateService = (props: OperateMaintenanceProps) => {
  const { type, carId, data, editData, onClose, reloadData, anyData } = props;
  const [loading, setLoading] = useState<boolean>(false); // 接口是否请求中
  const [serviceProblemType, setServiceProblemType] = useState<any>([]); // 问题来源的标签
  const [carOffLineCauseType, setCarOffLineCauseType] = useState<any>([]); // 车辆离线原因
  const [carRelationResult, setCarRelationResult] = useState<any>([]); // 车辆联系结果
  const [tagData, setTagData] = useState<any>([]); // 问题原因的标签
  const [uploadSuccessList, setUploadSuccessList] = useState<any>([]); // 上传成功的图片信息
  const [form] = Form.useForm();
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  const [serviceTaskDisposeEdit, setServiceTaskDisposeEdit] = useState<any>([]); // 客服任务编辑客服记录
  const [ifUploadImageVideo, setIfUploadImageVideo] = useState(false); // 是否上传图片或视频
  const [carPredictWorkTime, setCarPredictWorkTime] = useState<any>([]); // 预计复工时间

  useEffect(() => {
    getServiceProblemType()
    getWorkOrderTags()
    getCarOffLineCauseType()
    getCarPredictWorkTime()
    getCarRelationResult()
    edit()
    if (type == 'carDiagnosisAdd') {
      form.setFieldsValue({ tel: carId.car_principal_phone })
    }
    if (type == 'serviceTaskDisposeAdd') {
      form.setFieldsValue({ plateNumber: data.plateNumber })
      form.setFieldsValue({ frameNumber: data.frameNumber })
      form.setFieldsValue({ tel: data.tel })
      if (data.type == '故障车辆' || data.type == '监测车辆' || data.type == '离线车辆') {
        form.setFieldsValue({ category: '指标监测' })
      } else if (data.type == '专项回访') {
        form.setFieldsValue({ category: '日常回访' })
      }
    }
    if (type == 'serviceTaskDisposeEdit') {
      taskDisposeEdit()
    }
  }, []);

  // 客服任务编辑客服任务
  const taskDisposeEdit = async () => {
    const res = await serviceRecoedRequest({ taskId: data.id });
    form.setFieldsValue(res.data.data[0])
    setServiceTaskDisposeEdit(res.data.data[0])
  }

  // 编辑
  const edit = async () => {
    if (type === 'edit') {
      form.setFieldsValue(editData)
    }
  }

  // 提交客服记录
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      try {
        const params = { ...values }
        // if (params.category == null) {
        //   params.category = '指标监测'
        // }
        //对每个id 进行发送添加客服记录的请求
        if (type == 'serviceTaskBatchAdd') {
          params.taskIds = data
          params.taskState = params.treatmentStateType
          const res = await addServiceRecoedBatch(params);
          if (res.status == 200) {
            setSubmitNoRepetition(true)
          }
          if (res.data?.code === 0) {
            message.success('添加成功');
            onClose();
            reloadData();
          }
        } else {
          if (type == 'carDiagnosisAdd') {
            params.plateNumber = carId.plate_number
            params.frameNumber = carId.frame_number
            params.carId = carId.id
          }
          if (type == 'serviceTaskDisposeAdd') {
            const searchParams = {
              page: 1,
              limit: 50,
              plate_number: params.plateNumber,
            };
            const res = await carListRequest(searchParams);
            params.carId = res.data.data[0]?.id
            params.taskId = data.id
          }
          if (type == 'serviceRecordAdd') {
            const searchParams = {
              page: 1,
              limit: 50,
              plate_number: params.plateNumber,
            };
            const res = await carListRequest(searchParams);
            params.carId = res.data.data[0]?.id
          }
          if (uploadSuccessList.length) {
            params.resources = uploadSuccessList;
          }
          if (type === 'edit') {
            params.id = editData.id
          }
          if (type === 'serviceTaskDisposeEdit') {
            params.id = serviceTaskDisposeEdit.id
          }
          delete params.treatmentStateType
          const res = await addServiceRecoed(params);
          if (res.status == 200) {
            setSubmitNoRepetition(true)
          }
          if (res.data?.code === 0) {
            if (type === 'edit') {
              message.success('编辑成功');
            } else {
              message.success('添加成功');
            }
            onClose();
            reloadData();
            anyData(data,values)
          }
        }
      } catch (error) {
        console.log(error)
        setLoading(false);
      }
    }
  }

  // 获取问题来源
  const getServiceProblemType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.serviceProblemType],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setServiceProblemType(tags);
  }

  // 获取问题原因
  const getWorkOrderTags = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.maintainType],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title, id, isDisabled } = item;
      tags.push({ label: title, value: id, disabled: isDisabled });
    });
    setTagData(tags);
  }

  // 获取车辆离线原因
  const getCarOffLineCauseType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.carOffLineCause],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setCarOffLineCauseType(tags);
  }

  // 获取预计复工时间
  const getCarPredictWorkTime = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.carPredictWorkTime],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setCarPredictWorkTime(tags);
  }

  // 获取车辆联系结果
  const getCarRelationResult = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.carRelationResult],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setCarRelationResult(tags);
  }

  // 选择车辆获取车架号
  const onCarSelect = (value: any, options: any) => {
    form.setFieldsValue({
      frameNumber: options.frame_number,
    })
  }

  //是否上传视频或图片
  const IfUploadImageVideo = (checked: boolean) => {
    setIfUploadImageVideo(checked)
  }

  const treatmentState = [
    { label: '待处理', value: 9 },
    { label: '处理完成', value: 6 },
    { label: '派工单', value: 2 },
    { label: '协助车辆', value: 10 },
  ]

  return (
    <Modal
      title={type === 'add' ? '新增客服记录' : '编辑客服记录'}
      visible
      centered
      onCancel={onClose}
      onOk={() => form.submit()}
      okButtonProps={{ loading }}
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        scrollToFirstError
        onFinish={submit}
        initialValues={{adaptability: '配合'}}
      >
        {carId !== undefined || type == 'serviceTaskBatchAdd' ? null : <Form.Item
          label="请选择车辆"
          name="plateNumber"
          rules={[
            { required: true, message: '请选择车辆' }
          ]} >
          <CarSelect valueField="plate_number" onChange={onCarSelect} />
        </Form.Item>}
        {carId !== undefined || type == 'serviceTaskBatchAdd' ? null : <Form.Item
          label="请输入车架号"
          name="frameNumber"
          rules={[
            { required: true, message: '请输入车架号' }
          ]}>
          <Input placeholder="请输入车架号" />
        </Form.Item>}
        <Form.Item
          label="问题原因"
          name="types"
          rules={[
            { required: true, message: '请选择问题原因' }
          ]}
        >
          <Select
            mode="multiple"
            placeholder="请选择问题原因"
            options={tagData}
            allowClear
            showArrow
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          label="问题原因(自定义)"
          name="reason"
        >
          <TextArea rows={2} />
        </Form.Item>
        <Form.Item
          label="联系结果"
          name="result"
          rules={[
            { required: true, message: '请选择联系结果' }
          ]}
        >
          <Select
            placeholder="请选择联系结果"
            options={carRelationResult}
            allowClear
            showArrow
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          label="用户配合度"
          name="adaptability"
        >
          <Select
            placeholder="请选择用户配合度"
            options={[{name:'配合', value:'配合'},{name:'一般配合', value:'一般配合'},{name:'完全不配合', value:'完全不配合'}]}
            allowClear
            showArrow
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          label="问题来源"
          name="category"
          rules={[
            { required: true, message: '请选择问题来源' }
          ]}
        >
          <Select
            placeholder="请选择问题来源"
            options={serviceProblemType}
            allowClear
            showArrow
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        {type == 'serviceTaskDisposeAdd' ? <Form.Item shouldUpdate noStyle>
          {() => {
            if (data.type == '离线车辆') {
              return (
                <>
                  <Form.Item
                    label="车辆离线原因"
                    name="offlineReason"
                  >
                    <Select
                      placeholder="请选择车辆离线原因"
                      options={carOffLineCauseType}
                      allowClear
                      showArrow
                      showSearch
                      optionFilterProp="label"
                    />
                  </Form.Item>
                  <Form.Item
                    label="预计复工时间"
                    name="time"
                  >
                    <Select
                      placeholder="请选择预计复工时间"
                      options={carPredictWorkTime}
                      allowClear
                      showArrow
                      showSearch
                      optionFilterProp="label"
                    />
                  </Form.Item>
                </>
              )
            }
          }}
        </Form.Item> : null}
        <Form.Item label="记录内容" name="content">
          <TextArea rows={4} />
        </Form.Item>
        {type == 'serviceTaskBatchAdd' ? null : <Form.Item
          label="联系电话"
          name="tel"
          rules={[
            { required: true, message: '请输入联系电话' }
          ]}
        >
          <Input placeholder="请输入联系电话" />
        </Form.Item>}
        <Switch checkedChildren="上传图片/视频" unCheckedChildren="上传图片/视频" onChange={IfUploadImageVideo} style={{ marginBottom: '5px' }} />
        {ifUploadImageVideo &&
          <UploadImageVideo
            businessName="客服记录图片/视频"
            uploadFilesChange={(successList: any) => setUploadSuccessList(successList)}
            canUploadVideo
            defaultUploadFiles={type === 'edit' ? editData.viewResources == null ? [] : editData.viewResources.map((item: any) => ({
              contentType: item.contentType,
              key: new URL(item.url).pathname.slice(1),
              preview: item.url
            })) : []}
            limitSize={500}
            multiple
          />}
        {type == 'serviceTaskDisposeAdd' || type == 'serviceTaskDisposeEdit' || type == 'serviceTaskBatchAdd' ? <Form.Item
          label="客服任务处理状态"
          name="treatmentStateType"
          rules={[
            { required: true, message: '请选择客服任务处理状态' }
          ]}
        >
          <Select placeholder="请选择客服任务处理状态" options={treatmentState} allowClear showArrow showSearch optionFilterProp="label" />
        </Form.Item> : null}
        <Form.Item shouldUpdate noStyle>
          {() => {
            if (form.getFieldValue('treatmentStateType') == 9) {
              return (
                <Form.Item
                  label="期望完成时间"
                  name="expectedTime"
                  rules={[
                    { required: true, message: '请选择期望完成时间' }
                  ]}
                >
                  <DatePicker showTime style={{ width: '100%' }} />
                </Form.Item>
              )
            }
          }}
        </Form.Item>


      </Form>
    </Modal>
  )
}

export default OperateService;