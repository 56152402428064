// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","type":"jtDeviceAlarmCompare_type__mhGxj","time":"jtDeviceAlarmCompare_time__PQCTx","operate":"jtDeviceAlarmCompare_operate__gMwnF","speed":"jtDeviceAlarmCompare_speed__1cDcH","evalute":"jtDeviceAlarmCompare_evalute__SGVQO","headerBlock":"jtDeviceAlarmCompare_headerBlock__5CQhv","leftHeader":"jtDeviceAlarmCompare_leftHeader__SGejG","rightHeader":"jtDeviceAlarmCompare_rightHeader__V3NPy","scrollHeader":"jtDeviceAlarmCompare_scrollHeader__Sw9ky","headerGroupTitle":"jtDeviceAlarmCompare_headerGroupTitle__2Oxo4","headerTitle":"jtDeviceAlarmCompare_headerTitle__O1qpV","title":"jtDeviceAlarmCompare_title__l52HT","contentBlock":"jtDeviceAlarmCompare_contentBlock__FVK8Z","alarmIntervalBlock":"jtDeviceAlarmCompare_alarmIntervalBlock__H3iM4","leftPart":"jtDeviceAlarmCompare_leftPart__oZidT","rightPart":"jtDeviceAlarmCompare_rightPart__Nk9pL","tableRow":"jtDeviceAlarmCompare_tableRow__UBzh-","tableCell":"jtDeviceAlarmCompare_tableCell__GGlI7","formDeviceTitle":"jtDeviceAlarmCompare_formDeviceTitle__AjMqn","formDeviceBlock":"jtDeviceAlarmCompare_formDeviceBlock__6HHdm"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"type\":\"jtDeviceAlarmCompare_type__mhGxj\",\"time\":\"jtDeviceAlarmCompare_time__PQCTx\",\"operate\":\"jtDeviceAlarmCompare_operate__gMwnF\",\"speed\":\"jtDeviceAlarmCompare_speed__1cDcH\",\"evalute\":\"jtDeviceAlarmCompare_evalute__SGVQO\",\"headerBlock\":\"jtDeviceAlarmCompare_headerBlock__5CQhv\",\"leftHeader\":\"jtDeviceAlarmCompare_leftHeader__SGejG\",\"rightHeader\":\"jtDeviceAlarmCompare_rightHeader__V3NPy\",\"scrollHeader\":\"jtDeviceAlarmCompare_scrollHeader__Sw9ky\",\"headerGroupTitle\":\"jtDeviceAlarmCompare_headerGroupTitle__2Oxo4\",\"headerTitle\":\"jtDeviceAlarmCompare_headerTitle__O1qpV\",\"title\":\"jtDeviceAlarmCompare_title__l52HT\",\"contentBlock\":\"jtDeviceAlarmCompare_contentBlock__FVK8Z\",\"alarmIntervalBlock\":\"jtDeviceAlarmCompare_alarmIntervalBlock__H3iM4\",\"leftPart\":\"jtDeviceAlarmCompare_leftPart__oZidT\",\"rightPart\":\"jtDeviceAlarmCompare_rightPart__Nk9pL\",\"tableRow\":\"jtDeviceAlarmCompare_tableRow__UBzh-\",\"tableCell\":\"jtDeviceAlarmCompare_tableCell__GGlI7\",\"formDeviceTitle\":\"jtDeviceAlarmCompare_formDeviceTitle__AjMqn\",\"formDeviceBlock\":\"jtDeviceAlarmCompare_formDeviceBlock__6HHdm\"}";
        // 1734082438355
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  