/**
 * 报价记录
 */
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import {Button, Card, Checkbox, Col, Collapse, Form, Input, Row, Select} from 'antd';
import CommonTable from '@/components/commonTable';
import {useTable} from '@/utils/useTable';
import styles from '@/styles/pageContent.module.scss';
import {
  areaListRequest,
  carTypeListRequest,
  deletePremiumRequest,
  importPremiumRequestPath,
  premiumListRequest,
  premiumListRequestPath,
  quotationRequest
} from '@/service/offerRecord'
import {TagCategoryEnum} from '@/constants/tagManage';
import {tagListRequest} from '@/service/tagManage';
import ExportButton from '@/components/exportButton';
import DatePicker from '@/components/datePicker';
import QuotationModal from "@/pages/offerRecord/components/quotationModal";
import QuotationRecordModal, {QuotationRecordModalType} from "@/pages/offerRecord/components/quotationRecordModal";
import UploadExcel from "@/components/uploadExcel";
import useColumns from "@/pages/offerRecord/utils/columns.hook";
import AttachmentModal from "@/pages/offerRecord/components/attachmentModal";
import {jsPDF} from "jspdf";
import autoTable from "jspdf-autotable";
import {getInstalmentPdfConfig, getPdfConfig} from "@/pages/offerRecord/utils/getPdfConfig";
import {NCDOptions} from './constant/carType';
import {userListRequest} from '@/service/userManage';
import UnderWritingModal from "@/pages/offerRecord/components/underWritingModal";
import PermissionButton from '@/components/permissionButton';

const {RangePicker} = DatePicker;
const OfferRecord = () => {
  const [fuelType, setFuelType] = useState<any>([]); // 燃料类型
  const [areaCode, setAreaCode] = useState<any>([]); // 省
  const [cityCode, setCityCode] = useState<any>([]); // 市
  const [vehicleType, setVehicleType] = useState<any>([]); // 车辆类型
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [quotationModalVisible, setQuotationModalVisible] = useState(false); // 报价弹窗是否显示
  const [quotationRecordModalVisible, setQuotationRecordModalVisible] = useState(false); // 报价记录弹窗是否显示
  const [quotationRecordModalType, setQuotationRecordModalType] = useState(QuotationRecordModalType.Add); // 报价记录弹窗类型
  const [quotationRecordModalData, setQuotationRecordModalData] = useState({}); // 报价记录弹窗数据
  const [attachmentModalVisible, setAttachmentModalVisible] = useState(false); // 附件弹窗是否显示
  const [attachmentModalData, setAttachmentModalData] = useState({}); // 附件弹窗数据
  const [underWritingModalVisible, setUnderWritingModalVisible] = useState<boolean>(false); // 承保记录弹窗是否显示
  const [underWritingData, setUnderWritingData] = useState<any>({})//报价弹窗数据
  const [userData, setUserData] = useState([]); // 工单创建人员数据
  const [form] = Form.useForm();
  const [failTag, setFailTag] = useState(false)


  useEffect(() => {
    getFuelType()
    getCity()
    getUserData();
  }, []);

  // 获取用户数据
  const getUserData = async () => {
    const res = await userListRequest({page: 1, limit: 999});
    setUserData(res.data?.data.map((item: any) => {
      item.disabled = false;
      return item
    }) || [])
  }

  const getSortPremiumList = async (params: any) => {
    let res:any = {}
    if(failTag) {
      res = await quotationRequest(params)
    }
    if(!failTag) {
      res = await premiumListRequest(params)
    }
    const oriData = res.data?.data || []
    oriData.sort((last: any, next: any) => {
      const lastTime = new Date(last.updatedAt).getTime()
      const nextTime = new Date(next.updatedAt).getTime()
      return nextTime - lastTime
    })
    return res
  }

  const {page, limit, loading, data: oriData, totalCount, onPageChange, getData} = useTable({
    requestFunc: getSortPremiumList,
    initRequest: true,
    initPageSize: 40,
    searchParams
  });

  // 处理table的data，解决rowKey碰撞的问题
  const data = oriData.map((item: any, idx: number) => {
    return {...item, rowKey: `${item.id}--${idx}`}
  })
  // 处理搜索参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newParams = {...params};
    newParams.licenseNo = (params.licenseNo || '').trim();
    newParams.registerDate = params.registerDate ? dayjs(params.registerDate).format('YYYY-MM-DD') : undefined
    if (!newParams.plateNumber) {
      delete newParams.plateNumber;
    }
    if (newParams.day) {
      newParams.beginDate = dayjs(params.day[0]).format('YYYY-MM-DD')
      newParams.endDate = dayjs(params.day[1]).format('YYYY-MM-DD')
      delete newParams.day;
    }
    if (!newParams?.moldName?.trim()) {
      delete newParams.moldName
    }
    if (isExport) {
      newParams.export = true;
    }
    return newParams;
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams);
  };

  // 获取燃料类型
  const getFuelType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.fuelType],
    });
    const tags: any = [];
    (res.data?.data || []).map((item: any) => {
      const {title} = item;
      tags.push({label: title, value: title});
    });
    setFuelType(tags);
  }

  // 获取城市 以及 车辆类型  
  const getCity = async () => {
    const areaList = await areaListRequest();
    const carType = await carTypeListRequest()
    const aa: any = []
    const bb: any = []
    const cc: any = []
    areaList.data?.data.map((item: any) => {
      if (item.parentCode == null) {
        aa.push({label: item.name, value: item.code})
      } else {
        bb.push({label: item.name, value: item.code})
      }
    })
    carType.data?.data.map((item: any) => {
      if (item.parentCode !== null) {
        cc.push({label: item.name, value: item.name})

      }
    })
    setAreaCode(aa)
    setCityCode(bb)
    setVehicleType(cc)

  }

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  }

  //打开关闭创建报价弹窗
  const openCreateQuotationModal = (data?: any) => {
    console.log(data,'12312312312')
    setQuotationModalVisible(true);
    setQuotationRecordModalData(data)
  }
  const onCloseQuotationModal = () => {
    setQuotationModalVisible(false);
    setQuotationRecordModalData({})
  }
  //打开关闭新增编辑报价记录弹窗
  const openQuotationRecordModal = (type: QuotationRecordModalType, data?: any) => {
    setQuotationRecordModalType(type)
    setQuotationRecordModalData(data || {})
    setQuotationRecordModalVisible(true)
  }
  const onCloseQuotationRecordModal = () => {
    setQuotationRecordModalVisible(false)
    onSearch(searchParams)
  }

  //打开关闭查看报价记录附件弹窗
  const openAttachmentModal = (data: any) => {
    setAttachmentModalData(data)
    setAttachmentModalVisible(true)
  }
  const onCloseAttachmentModal = () => {
    setAttachmentModalVisible(false)
    onSearch(searchParams)
  }

  //删除报价记录
  const deleteQuotationRecord = async (id: string) => {
    try {
      await deletePremiumRequest(id)
      await onSearch(searchParams)
    } catch (e) {
      console.warn(e)
    }
  }


  //导出报价单
  const exportPdfFn = (item: any) => {

    const licenseNo = item.licenseNo || ''
    const operator = item.operator || ''
    const createdAt = item.createdAt || ''
    const carOwnerName = item.carOwnerName || ''
    const bizTotalPlate = Number(item.bizTotalPlate) || 0
    const cheSunBaoE = Number(item.negotiatePrice) || 0
    const sanZheBaoE = Number(item.sanZhe) || 0
    const sijiBaoE = Number(item.siJi) || 0
    const chengKeBaoE = Number(item.chengKe) || 0
    const deviceType = item.deviceType || ''
    const instalment = item.instalment
    const threeElectricityFlag = item.threeElectricityFlag
    const selfBurningFlag = item.selfBurningFlag
    const fuelType_ = item.fuelType
    const selfBurningFee = Number(item.selfBurningFee) || 0

    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: instalment ? [270, 327] : [210, 327]
    });
    const pageWidth = doc.internal.pageSize.getWidth();
    const imgSize = 32;
    doc.addFont('/font/shsc.ttf', 'shsc', 'normal');
    doc.setFont('shsc');

    doc.addImage('/images/zfxy.png', 'PNG', 10, 10, imgSize, imgSize);

    doc.addImage('/images/zfxyer.jpg', 'JPG', pageWidth - imgSize - 10 - (instalment ? 65 : 0), 10, imgSize, imgSize);

    autoTable(
      doc,
      getPdfConfig({
        operator,
        createdAt,
        licenseNo,
        carOwnerName,
        bizTotalPlate,
        cheSunBaoE,
        sanZheBaoE,
        sijiBaoE,
        chengKeBaoE,
        deviceType,
        instalment,
        fuelType: fuelType_,
        threeElectricityFlag,
        selfBurningFlag,
        selfBurningFee
      }, instalment ? 180 : void 0),
    );

    if (instalment) {
      autoTable(doc, getInstalmentPdfConfig({
        bizTotalPlate,
        deviceType,
        selfBurningFee
      }));
    }


    doc.save(`${carOwnerName}-报价单.pdf`);


  }

  //根据报价记录生成承保记录
  const generateQuotationRecord = (data: any) => {
    setUnderWritingData(data)
    setUnderWritingModalVisible(true)
  }

  const columns = useColumns({
    exportPdfFn: exportPdfFn,
    copyFn: (item: any) => openQuotationRecordModal(QuotationRecordModalType.Copy, item),
    delFn: deleteQuotationRecord,
    openAttachmentFn: openAttachmentModal,
    generateQuotationFn: generateQuotationRecord,
    repeatFn: (item: any) => {openCreateQuotationModal(item)}
  })

  const failChange = (e: any) => {
    setFailTag(e.target.checked)
  }
  return (
    <>
      <Card bordered={false} className={styles.fixPageContentBox}>
        <Collapse defaultActiveKey={'searchForm'}>
          <Collapse.Panel key="searchForm" header="筛选信息">
            <Form onFinish={onSearch} form={form} layout="vertical" autoComplete="off">
              <Row gutter={24}>
                <Col span={4}>
                  <Form.Item label="报价时间范围" name="day">
                    <RangePicker allowClear={false} style={{width: '100%'}}/>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="省份" name="areaCode">
                    <Select placeholder="请选择省份" options={areaCode} allowClear showArrow showSearch
                            optionFilterProp="lable"/>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="品牌型号" name="moldName">
                    <Input placeholder="请输入品牌型号" allowClear/>
                  </Form.Item>
                </Col>
                {/* <Col span={4}>
                  <Form.Item label="城市" name="cityCode">
                    <Select placeholder="请选择城市" options={cityCode} allowClear showArrow
                            showSearch optionFilterProp="lable"/>
                  </Form.Item>
                </Col> */}
                <Col span={4}>
                  <Form.Item label="市场归属" name="carOwnerDepartment">
                    <Input placeholder="请输入市场归属" allowClear/>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="报价人" name="carOwnerName">
                    {/* <Input placeholder="请输入报价人" allowClear/> */}
                    <Select
                      placeholder="请选择工单处理人"
                      options={userData}
                      allowClear
                      showArrow
                      showSearch
                      fieldNames={{
                        label: 'nickname',
                        value: 'nickname'
                      }}
                      optionFilterProp="nickname"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="车牌号" name="licenseNo">
                    <Input placeholder="请输入车牌号" allowClear onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('licenseNo', newV)}}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={4}>
                  <Form.Item label="车架号" name="carVin">
                    <Input placeholder="请输入车架号" allowClear onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('carVin', newV)}}/>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="车辆类型" name="vehicleType">
                    <Select placeholder="请选择车辆类型" options={vehicleType} allowClear showArrow
                            showSearch optionFilterProp="label"/>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="车辆所有人" name="carOwnersName">
                    <Input placeholder="请输入车辆所有人" allowClear/>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="燃料类型" name="fuelType">
                    <Select placeholder="请选择燃料类型" options={fuelType} allowClear showArrow
                            showSearch optionFilterProp="label"/>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="设备类型" name="deviceType">
                    {/* <Input placeholder="请输入设备类型" allowClear/>
                     */}
                     <Select allowClear options={[
                      {value: 'AEBS', label: 'AEBS'},
                      {value: 'ADAS', label: 'ADAS'}
                     ]}></Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="NCD系数" name="ncd">
                    <Select placeholder="请输入NCD系数" allowClear options={NCDOptions}/>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="初登日期" name="registerDate">
                    <DatePicker style={{width: '100%'}} placeholder='请选择日期' allowClear/>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label=" " name="">
                    <Checkbox onChange={failChange}>报价失败记录</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="&nbsp;">
                    <div className={styles.searchBox}>
                      <Button type="primary" htmlType="submit">搜索</Button>
                      <ExportButton url={premiumListRequestPath} fileName={`报价记录`} getParamsFunc={getExportParams}
                                    requestMethod="GET" permissionKey='/car-admin/offer-recode/export'/>
                    </div>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="">
                    <div className={styles.searchBox}>
                      {/*<Button type={"primary"} onClick={openCreateQuotationModal}>创建报价</Button>*/}
                      <PermissionButton type="primary"  onClick={() => openQuotationRecordModal(QuotationRecordModalType.Add)} operateType='/car-admin/offer-recode/new-recode'>
                      新增报价记录</PermissionButton>
                      {/* <Button type={"primary"}
                              onClick={() => openQuotationRecordModal(QuotationRecordModalType.Add)}>
                        新增报价记录
                      </Button> */}
                      <PermissionButton type="primary" onClick={openCreateQuotationModal} operateType='/car-admin/offer-recode/create-recode'>创建报价</PermissionButton>
                      {/* <Button type="primary" onClick={openCreateQuotationModal}>创建报价</Button> */}
                      {/* <Button href="baojia_templete.xlsx">下载导入模板文件</Button> */}
                      <PermissionButton  href="baojia_templete.xlsx"  onClick={openCreateQuotationModal} operateType='/car-admin/offer-recode/download-template'>下载导入模板文件</PermissionButton>
                      <UploadExcel
                        uploadRequestPath={importPremiumRequestPath}
                        // uploadPermissionKey="/car-admin/accident-record/import"
                        uploadButtonProps={{children: '批量导入'}}
                        uploadSuccessFunc={() => {
                          onSearch(searchParams)
                        }}
                        uploadPermissionKey="/car-admin/offer-recode/batch-import"
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Collapse.Panel>
        </Collapse>

        <CommonTable
          rowKey={'rowKey'}
          columns={columns} dataSource={data} bordered loading={loading}
          pagination={{
            total: totalCount,
            current: page,
            pageSize: limit,
            onChange: onPageChange,
          }}
        />
      </Card>
      {/*创建报价弹窗*/}
      <QuotationModal open={quotationModalVisible} onCancel={onCloseQuotationModal} data={quotationRecordModalData}></QuotationModal>
      {/*  新增编辑报价弹窗*/}
      <QuotationRecordModal columns={columns} open={quotationRecordModalVisible} data={quotationRecordModalData}
                            onCancel={onCloseQuotationRecordModal}
                            modalType={quotationRecordModalType}></QuotationRecordModal>
      {/*  查看报价记录附件的弹窗*/}
      {
        attachmentModalVisible &&
        <AttachmentModal open={attachmentModalVisible} onClose={onCloseAttachmentModal}
                         data={attachmentModalData}></AttachmentModal>}
      {/*根据报价记录生成承保记录的弹窗*/}
      {
        underWritingModalVisible &&
        <UnderWritingModal data={underWritingData} closeModal={() => setUnderWritingModalVisible(false)}/>
      }
    </>
  )
}

export default OfferRecord;
