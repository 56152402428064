/**
 * 新车队报告
 */
import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Descriptions,
  InputNumber,
  Select,
  Input,
  TableProps,
  message,
} from "antd";
import dayjs from "dayjs";
import * as echarts from "echarts/core";
import DatePicker from "@/components/datePicker";
import LoadingWrapper from "@/components/loadingWrapper";
import CommonTable from "@/components/commonTable";
import ExportButton from "@/components/exportButton";
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import {
  fleetRiskReportRequest,
  motorcadeCarList,
  fleetRiskReportExportRequestPath,
  cateAllCompanyRequest,
} from "@/service/fleetRisk";
import { limitDayRange } from "@/utils/operateSearchParams";
import styles from "./index.module.scss";
import { companyListRequest } from "@/service/companyManage";
import PermissionButton from "@/components/permissionButton";
import { downloadUrl } from "@/utils/download";
import {
  carListRequest,
  CarListRequestParams,
  carReportPath,
  reportListRequest,
  createReportPathRequest
} from "@/service/carManage";
import { getInnerPermissionEnable } from "@/utils/permission";

const levelOptions = [
  { value: "高", label: "高" },
  { value: "中", label: "中" },
  { value: "低", label: "低" },
];

const { RangePicker } = DatePicker;
const CarReprot = () => {
  const [carData, setCarData] = useState([]); // 车辆数据
  const [remarkContent, setRemarkContent] = useState<any>(); // 备注
  const [loading, setLoading] = useState(false); // 是否在获取报告中
  const [allCompany, setAllCompany] = useState<any>([]); // 车队选择
  const [totalCount, setTotalCount] = useState(0); // 车辆总数
  const [pageNum, setPageNum] = useState(1); // 页码
  const [form] = Form.useForm();
  const [pageSize, setPageSize] = useState(40); // 每一页显示的数量
  const [serachParams, setSerachParams] = useState({
    cate_id: [],
  }); // 搜索相关的参数
  const [downloadList, setDownloadList] = useState<any[]>([]);

  useEffect(() => {
    getCateAllCompanyData();
    form.setFieldValue("time", [
      dayjs().day(-7).startOf("week"),
      dayjs().day(-7).endOf("week"),
    ]);
  }, []);

  const getCateAllCompanyData = async () => {
    // const res = await companyListRequest({page: 1, limit: 20000, name: ''});
    const res = await cateAllCompanyRequest();
    const data = res.data?.data || [];
    //循环遍历data
    const aa: any = [];
    data.forEach((item: any) => {
      // aa.push({label: item.name, value: item.name})
      aa.push({ label: item.title, value: item.title });
    });
    setAllCompany(aa);
  };

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = form.getFieldsValue();
    newSearchParams.beginDate = newSearchParams.time[0].format("YYYY-MM-DD");
    newSearchParams.endDate = newSearchParams.time[1].format("YYYY-MM-DD");
    delete newSearchParams.time;
    console.log(newSearchParams);
    if (newSearchParams) {
      setSerachParams(newSearchParams);
      getCarData({
        page: 1,
        limit: pageSize,
        ...newSearchParams,
      });
    }
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    const newSearchParams = Object.assign({}, values, { export: true });
    newSearchParams.beginDate = newSearchParams.time[0].format("YYYY-MM-DD");
    newSearchParams.endDate = newSearchParams.time[1].format("YYYY-MM-DD");
    delete newSearchParams.time;
    return newSearchParams;
  };

  // 获取车辆数据
  const getCarData = async (params: CarListRequestParams) => {
    try {
      setCarData([]);
      setLoading(true);
      const res = await reportListRequest(params);

      const resData = res.data?.data || [];
      const resDataWithIdxKey = resData.map((item: any, index: number) => {
        return {
          ...item,
          key: `${item.carId}-${index}`,
        };
      });
      setCarData(resDataWithIdxKey);
      setTotalCount(res.data?.count || 0);
      setPageNum(params.page);
      setLoading(false);
    } catch (error) {
      console.warn("error", error);
      setLoading(false);
    }
  };
  const creatReport = (id: any) => {
    createReportPathRequest({id}).then(() => {
      getCarData({
        page: pageNum,
        limit: pageSize,
        ...serachParams,
      });
    })
  }

  // 表格列显示
  const columns = [
    { title: "车队名称", dataIndex: "name", width: 110 },
    { title: "统计日期", dataIndex: "week", width: 110 },
    { title: "评分", dataIndex: "score", width: 110 },
    { title: "评级", dataIndex: "grade", width: 110 },
    { title: "车辆总数", dataIndex: "totalCount", width: 110 },
    { title: "风险车辆数", dataIndex: "riskCount", width: 110 },
    {
      title: "车队报告",
      dataIndex: "company",
      width: 110,
      render: (value: any, item: any) => {
        return (
          <>
            {item.url && (
              <PermissionButton
                operateType="/car-admin/report/report-edit"
                type="link"
                onClick={() =>
                  downloadUrl(
                    item.url,
                    `${item.name}_${item.week}_车队报告.ppt`
                  )
                }
              >
                下载
              </PermissionButton>
            )}
            {(!item.url && (item.state === null || item.state === 1)) && (
              <PermissionButton
                operateType="/car-admin/report/report-edit"
                type="link"
                onClick={() =>{creatReport(item.id)}
                }
              >
                生成报告
              </PermissionButton>
            )}
            {!item.url && item.state === 0 && (
              <span>生成中</span>
            )}
          </>
        );
      },
      fixed: "right" as "right",
    },
  ];

  // 翻页器信息改变
  const onPageChange = (page: number, newPageSize: number) => {
    const newPageNum = newPageSize !== pageSize ? 1 : page;
    setPageNum(newPageNum);
    setPageSize(newPageSize);
    getCarData({
      page: newPageNum,
      limit: newPageSize,
      ...serachParams,
    });
  };

  const rowSelection: TableProps<any>["rowSelection"] = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setDownloadList(selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.url === null, // Column configuration not to be checked
      name: record.name,
    }),
  };

  const downloadFileAllFn = () => {
    if (!downloadList.length) {
      message.error("请先选择数据");
    } else {
      console.log(downloadList, 'downloadList')
      downloadList.forEach((item, index) => {
        setTimeout(() => {
          downloadUrl(item.url, `${item.name}_${item.week}_车队报告.ppt`);
        }, 1000 * index)
      });
    }
  };

  useEffect(() => {
    onSearch({})
  }, [])

  return (
    <Card bordered={false} className={styles.fleetRisk}>
      <Form
        onFinish={onSearch}
        form={form}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={24} wrap>
          <Col span={6}>
            <Form.Item label="报告周期" name="time">
              <RangePicker
                allowClear={false}
                style={{ width: "100%" }}
                ranges={{
                  '近三天': [dayjs().subtract(2, "day").endOf("day"), dayjs().startOf("day")],
                  '近一周': [
                    dayjs().subtract(1, 'weeks'), dayjs().startOf("day"),

                  ],
                  '近两周': [
                    dayjs().subtract(2, 'weeks'), dayjs().startOf("day"),
                  ],
                  '近一月': [
                    dayjs().subtract(1, 'month'), dayjs().startOf("day"),
                  ],
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="车队选择"
              name="name"
            >
              <Select
                showSearch
                placeholder="请选择"
                style={{ width: "100%" }}
                options={allCompany}
                allowClear
              />
            </Form.Item>
          </Col>
          {getInnerPermissionEnable('/car-admin/report/level') &&  <Col span={6}>
            <Form.Item label="评级" name="grade">
              <Select
                placeholder="请选择"
                style={{ width: "100%" }}
                options={levelOptions}
                allowClear
              />
            </Form.Item>
          </Col>}
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item shouldUpdate>
              {() => (
                <div className={styles.searchBox}>
                  <Button type="primary" htmlType="submit">
                    搜索
                  </Button>
                  <ExportButton
                    url={carReportPath}
                    fileName={`${form.getFieldValue("name")}车队报告`}
                    getParamsFunc={getExportParams}
                    requestMethod="GET"
                    permissionKey="/car-admin/fleet-risk/export"
                  />
                  <Button onClick={downloadFileAllFn}>批量导出</Button>
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <LoadingWrapper spinning={loading}>
        <CommonTable
          rowSelection={{ type: "checkbox", ...rowSelection }}
          rowKey="key"
          columns={columns}
          dataSource={carData}
          pagination={{
            total: totalCount,
            current: pageNum,
            pageSize,
            onChange: onPageChange,
          }}
          bordered
          loading={loading}
          // tableInScreen={false}
          scroll={{ x: "100%", y: "100%" }}
        />
      </LoadingWrapper>
    </Card>
  );
};

export default CarReprot;
