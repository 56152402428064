// extracted by mini-css-extract-plugin
export default {"trackPlaybackBox":"playbackMonitoring_trackPlaybackBox__weJxW","carTree":"playbackMonitoring_carTree__-YIFY","monitoring":"playbackMonitoring_monitoring__tOjod","monitoringTitle":"playbackMonitoring_monitoringTitle__HFEGG","monitoringButton":"playbackMonitoring_monitoringButton__FtFID","rows":"playbackMonitoring_rows__KuZhc","monitoringContent":"playbackMonitoring_monitoringContent__eSGvs","text":"playbackMonitoring_text__S5vyP"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"trackPlaybackBox\":\"playbackMonitoring_trackPlaybackBox__weJxW\",\"carTree\":\"playbackMonitoring_carTree__-YIFY\",\"monitoring\":\"playbackMonitoring_monitoring__tOjod\",\"monitoringTitle\":\"playbackMonitoring_monitoringTitle__HFEGG\",\"monitoringButton\":\"playbackMonitoring_monitoringButton__FtFID\",\"rows\":\"playbackMonitoring_rows__KuZhc\",\"monitoringContent\":\"playbackMonitoring_monitoringContent__eSGvs\",\"text\":\"playbackMonitoring_text__S5vyP\"}";
        // 1734082437488
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  