// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","alarmMonitor":"alarmMonitor_alarmMonitor__CzFT7","alarmTabs":"alarmMonitor_alarmTabs__F+jh6","alarmTabItemBox":"alarmMonitor_alarmTabItemBox__6zph8","alarmTabItem":"alarmMonitor_alarmTabItem__TwUWA","macthActiveAlarmTabItem":"alarmMonitor_macthActiveAlarmTabItem__Fi0Ex","macthAlarmTabItem":"alarmMonitor_macthAlarmTabItem__gY6Ut","activeAlarmTabItem":"alarmMonitor_activeAlarmTabItem__rkHx2","iconBox":"alarmMonitor_iconBox__fWZ0R"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"alarmMonitor\":\"alarmMonitor_alarmMonitor__CzFT7\",\"alarmTabs\":\"alarmMonitor_alarmTabs__F+jh6\",\"alarmTabItemBox\":\"alarmMonitor_alarmTabItemBox__6zph8\",\"alarmTabItem\":\"alarmMonitor_alarmTabItem__TwUWA\",\"macthActiveAlarmTabItem\":\"alarmMonitor_macthActiveAlarmTabItem__Fi0Ex\",\"macthAlarmTabItem\":\"alarmMonitor_macthAlarmTabItem__gY6Ut\",\"activeAlarmTabItem\":\"alarmMonitor_activeAlarmTabItem__rkHx2\",\"iconBox\":\"alarmMonitor_iconBox__fWZ0R\"}";
        // 1734082451347
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  