/**
 * 承保记录
 */
import React, {useEffect, useState} from 'react';
import {Card, Form, Row, Col, Button, Input, message, Popconfirm, Select, Checkbox, Collapse, Modal} from 'antd';
import dayjs from 'dayjs';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import PermissionButton from '@/components/permissionButton';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import {useTable} from '@/utils/useTable';
import {
  underwritingListRequest,
  underwritingListRequestPath,
  deleteUnderwritingRequest,
  getProvincialListRequest,
  getCityListRequest,
  importUnderwritingRequestPath,
  underwritingDetailRequest,
  upsertInsureFeeGenerateRequest
} from '@/service/underwritingRecord';
import OperateModal from './components/operateModal';
import styles from '@/styles/pageContent.module.scss';
import {DownloadOutlined, FileZipOutlined, EyeOutlined} from '@ant-design/icons';
import {downloadFile} from '@/utils/download';
import PdfPreviewModal from '@/components/pdfPreviewModal';
import PayinfoModal from './components/payinfoModal';
import {carTypeListRequest} from '@/service/offerRecord';
import {tagListRequest} from '@/service/tagManage';
import {TagCategoryEnum} from '@/constants/tagManage';
import {installmentTypeData, payStateData, caseFlagDataa} from '@/constants/underwritingRecord';
import PolicyCancellationModal from './components/policyCancellationModal';
import ChangeCancellationModal from './components/changeCancellationModal';
import UploadExcel from '@/components/uploadExcel';
import DatePicker from '@/components/datePicker';
import {insureListRequest} from '@/service/insurerManage';
import {useSelector} from 'react-redux';
import { companyListRequest } from '@/service/companyManage';
import OperateContractModal from '../contractManage/components/operateModal';

const {RangePicker} = DatePicker;
const {Panel} = Collapse;
const UnderwritingRecord = () => {
  const tabPagesStore = useSelector((state: any) => state.tabPagesStore);
  const {activeKey, tabParams} = tabPagesStore;
  const [searchParams, setSearchParams] = useState({contractFlag: '', accidentFlag: ''}); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息
  const [fuelType, setFuelType] = useState<any>([]); // 燃料类型
  const [areaCode, setAreaCode] = useState<any>([]); // 省
  const [cityCode, setCityCode] = useState<any>([]); // 市
  const [vehicleType, setVehicleType] = useState<any>([]); // 车辆类型
  const [carServeState, setCarServeState] = useState<any>([]); // 运维评级
  const [pdfPreviewInfo, setPdfPreviewInfo] = useState<any>(null); // PDF合同预览的信息
  const [form] = Form.useForm();
  const {page, limit, loading, data, totalCount, onPageChange, getData} = useTable({
    requestFunc: underwritingListRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });
  const [commercialCompany, setCommercialCompany] = useState<any>([]); // 承保保司
  const [serviceState, setServiceState] = useState<any>([]); // 服务状态
  const [mode, setMode] = useState<any>([]); // 承保模式
  const [closeFeeState, setCloseFeeState] = useState<any>([]); // 结算状态
  const [copyModal, setCopyModal] = useState<any>(null); // 操作拷贝的信息
  const [companyList, setCompanyList] = useState<any>([]); // 车队列表

  // 监听跳转时携带参数的形式
  useEffect(() => {
  }, [activeKey, tabParams]);

  useEffect(() => {
    getFuelType()
    getCity()
    getServiceProblemType()
    getCommercialCompany()
    getServiceState()
    getMode()
    getCloseFeeState() //结算状态
    getCompanyList()
    .then(companyList => setCompanyList(companyList))
  }, []);

  const getCompanyList = async () => {
    const res = await companyListRequest({page: 1, limit: 20000, name: ''});
    const data = res.data?.data || [];
    const companyList = data.map((item: any) => {
      return {
        label: item.name,
        value: item.name,
        key: item.id
      }
    })
    return companyList || []
  }

  // 获取承保保司
  const getCommercialCompany = async () => {
    const res = await insureListRequest('');
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const {name} = item;
      tags.push({label: name, value: name});
    });
    setCommercialCompany(tags);
  }

  // 获取服务状态
  const getServiceState = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.serviceState],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const {title} = item;
      tags.push({label: title, value: title});
    });
    setServiceState(tags);
  }

  // 获取结算状态
  const getCloseFeeState = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.closeFeeState],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const {title} = item;
      tags.push({label: title, value: title});
    });
    setCloseFeeState(tags);
  }

  // 获取承保模式
  const getMode = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.mode],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const {title} = item;
      tags.push({label: title, value: title});
    });
    setMode(tags);
  }

  // 获取城市 以及 车辆类型  
  const getCity = async () => {
    const provincialList = await getProvincialListRequest();
    const cityList = await getCityListRequest();
    const carType = await carTypeListRequest()
    const aa: any = []
    const bb: any = []
    const cc: any = []
    provincialList.data?.data.map((item: any) => {
      aa.push({label: item.name, value: item.name})
    })
    cityList.data?.data.map((item: any) => {
      bb.push({label: item.name, value: item.name})
    })
    carType.data?.data.map((item: any) => {
      if (item.parentCode !== null) {
        cc.push({label: item.name, value: item.name})

      }
    })
    setAreaCode(aa)
    setCityCode(bb)
    setVehicleType(cc)
  }


  // 获取燃料类型
  const getFuelType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.fuelType],
    });
    const tags: any = [];
    (res.data?.data || []).map((item: any) => {
      const {title} = item;
      tags.push({label: title, value: title});
    });
    setFuelType(tags);
  }

  // 获取运维评级
  const getServiceProblemType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.carEvaluation],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const {title} = item;
      tags.push({label: title, value: title});
    });
    setCarServeState(tags);
  };


  //是否逾期
  const overdueFlagData = [
    {label: '逾期', value: 1},
    {label: '正常', value: 0},
  ];

  // 处理搜索参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newParams = {...params};
    newParams.plateNumber = (params.plateNumber || '').trim();
    if (!newParams.plateNumber) {
      delete newParams.plateNumber;
    }
    if (newParams.payDate) {
      newParams.payDate = dayjs(params.payDate).format('YYYY-MM-DD');
    }
    // 起保开始和到期日期区间
    if (newParams.commercialBeginDate) {
      newParams.commercialBeginDateBegin = dayjs(params.commercialBeginDate[0]).format('YYYY-MM-DD')
      newParams.commercialBeginDateEnd = dayjs(params.commercialBeginDate[1]).format('YYYY-MM-DD')
      delete newParams.commercialBeginDate;
    }
    if (newParams.commercialEndDate) {
      newParams.commercialEndDateBegin = dayjs(params.commercialEndDate[0]).format('YYYY-MM-DD')
      newParams.commercialEndDateEnd = dayjs(params.commercialEndDate[1]).format('YYYY-MM-DD')
      delete newParams.commercialEndDate;
    }
    if (isExport) {
      newParams.export = true;
    }
    return newParams;
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams);
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  }

  //删除
  const deleteUser = async (id: number) => {
    const res = await deleteUnderwritingRequest(id)
    if (res.data?.code === 0) {
      message.success('删除成功');
      getData();
    }
  }

  // PDF预览
  const onPdfPreview = (url: string, fileName: string) => {
    setPdfPreviewInfo({
      url,
      fileName,
    });
  }

  //合同异常
  const onChange = (e: any) => {
    if (e.target.checked) {
      searchParams.contractFlag = '1'
      onSearch(searchParams)
    } else {
      onSearch({})
    }
  }

  //故障车
  const onChangee = (e: any) => {
    if (e.target.checked) {
      searchParams.accidentFlag = '1'
      onSearch(searchParams)
    } else {
      onSearch({})
    }
  }

  const submit = async (values: any) => {
    //清空
    setCopyModal(null)
    try {
      const params = {...values};
      const res = await underwritingDetailRequest(params);
      if (res.data?.code === 0) {
        setOperateInfo({type: 'copy', data: res.data?.data})
      }
    } catch (error) {
      console.log(error);
    }
  }

  //重新分期
  const onInsureFeeGenerateClick = async (values: any) => {
    const res = await upsertInsureFeeGenerateRequest(values);
    if (res.data.code == 0) {
      message.success('修改成功');
    }
  }

  // 表格列显示
  const columns = [
    {
      title: '承保单号',
      dataIndex: 'commercialNumber',
      width: 100,
      ellipsis: {showTitle: false},
      render: (value: string) => value ? <RenderEllipsisColumn text={value || '/'}/> : ''
    },
    {
      title: '合同号',
      dataIndex: 'contractSn',
      width: 110,
    },
    {
      title: '合同状态',
      dataIndex: 'contractState',
      width: 110,
    },
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110
    },
    {
      title: '车架号',
      dataIndex: 'frameNumber',
      width: 110,
    },
    {
      title: '车辆所有人',
      dataIndex: 'carOwner',
      width: 110,
    },
    {
      title: '投保人',
      dataIndex: 'applicant',
      width: 110,
    },
    {
      title: '承保模式',
      dataIndex: 'mode',
      width: 110,
    },
    {
      title: '服务状态',
      dataIndex: 'serviceState',
      width: 110,
    },
    {
      title: '原保司',
      dataIndex: 'mainBodyChange',
      width: 110,
    },
    {
      title: '承保保司',
      dataIndex: 'commercialCompany',
      width: 110,
    },
    {
      title: '城市',
      dataIndex: 'area',
      width: 110,
      render: (value: string, item: any) => (
        value ? <div>
          {item.area}/{item.city}
        </div> : null
      )
    },
    {
      title: '市场归属',
      dataIndex: 'department',
      width: 110,
    },
    {
      title: '渠道经理',
      dataIndex: 'channelManager',
      width: 110,
    },
    {
      title: '合伙人',
      dataIndex: 'partner',
      width: 110,
    },
    {
      title: '出单人',
      dataIndex: 'issuer',
      width: 110,
    },
    {
      title: '车辆类型',
      dataIndex: 'carType',
      width: 110,
    },
    {
      title: '分期类型',
      dataIndex: 'installmentType',
      width: 110,
    },
    {
      title: '分期数',
      dataIndex: 'installmentCount',
      width: 110,
    },
   
    {
      title: '已支付分期数',
      dataIndex: 'paidInstallmentCount',
      width: 110,
    },
    {
      title: '是否逾期',
      dataIndex: 'overdueFlag',
      width: 100,
      ellipsis: {showTitle: false},
      render: (value: number) => value == 1 ? <RenderEllipsisColumn text={'逾期'}/> :
        <RenderEllipsisColumn text={'正常'}/>
    },
    {
      title: '服务费分期金额',
      dataIndex: 'needPaidServiceFee',
      width: 110,
    },
    {
      title: '距下期缴费剩余天数',
      dataIndex: 'nextPayDays',
      width: 110,
      render: (value: string) => {
        return value === null  ? "-" : value
      }
    },
    {
      title: '应补缴总服务费',
      dataIndex: 'needPaidServiceFee',
      width: 110,
      render: (value: string) => {
        return value || '0.00'
      }
    },
    {
      title: '应缴总滞纳金',
      dataIndex: 'needPaidLateFee',
      width: 110,
      render: (value: string) => {
        return value || '0.00'
      }
    },
    {
      title: '应补缴总金额',
      dataIndex: '',
      width: 110,
      render: (_:string, item: any) => {
        return (item.needPaidLateFee + item.needPaidServiceFee).toFixed(2)
      }
    },
    {
      title: '逾期期数',
      dataIndex: 'overdueCount',
      width: 110,
      render: (value: string) => {
        return value || '0'
      }
    },
    {
      title: '结算状态',
      dataIndex: 'settlementState',
      width: 110,
    },
    {
      title: '起保时间(商业)',
      dataIndex: 'commercialBeginDate',
      width: 150,
      render: (value: string) => value ? <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD')}/> : '',
    },
    {
      title: '到期时间(商业)',
      dataIndex: 'commercialEndDate',
      width: 150,
      ellipsis: {showTitle: false},
      render: (value: string) => value ? <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD')}/> : ''
    },
    {
      title: '保单号（交强）',
      dataIndex: 'compulsoryNumber',
      width: 110,
    },
    {
      title: '保司（交强）',
      dataIndex: 'compulsoryCompany',
      width: 110,
    },
    {
      title: '燃油类型',
      dataIndex: 'fuelType',
      width: 100,
      ellipsis: {showTitle: false},
      render: (value: string) => value ? <RenderEllipsisColumn text={value}/> : ''
    },
    {
      title: '运维评级',
      dataIndex: 'operateState',
      width: 100,
      ellipsis: {showTitle: false},
      render: (value: string) => value ? <RenderEllipsisColumn text={value}/> : ''
    },
    {
      title: '投保人',
      dataIndex: 'applicant',
      width: 110,
    },
    {
      title: '三者',
      dataIndex: 'sanZheBaoE',
      width: 110,
    },
    {
      title: '司机',
      dataIndex: 'siJiBaoE',
      width: 110,
    },
    {
      title: '乘客',
      dataIndex: 'chengKeBaoE',
      width: 110,
    },
    {
      title: '车损',
      dataIndex: 'cheSunBaoE',
      width: 110,
    },
    {
      title: '安装设备',
      dataIndex: 'device',
      width: 110,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 110,
    },
    {
      title: '保费总金额（元）',
      dataIndex: 'totalFee',
      width: 110,
    },
    {
      title: '商业总保费（元）',
      dataIndex: 'commercialFee',
      width: 90,
    },
    {
      title: '交强总保费（元）',
      dataIndex: 'compulsoryFee',
      width: 90,
    },
    {
      title: '交强险保单号',
      dataIndex: 'compulsoryNumber',
      width: 110,
    },
    {
      title: '已缴纳费用',
      dataIndex: 'paidFee',
      width: 110,
    },
    {
      title: '优选费用',
      dataIndex: 'rewardFee',
      width: 110,
    },
    {
      title: '退费',
      dataIndex: 'surrenderFee',
      width: 110,
    },
    {
      title: '交强险起始时间',
      dataIndex: 'compulsoryBeginDate',
      width: 100,
      ellipsis: {showTitle: false},
      render: (value: string) => value ? <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD')}/> : ''
    },
    {
      title: '交强险结束时间',
      dataIndex: 'compulsoryEndDate',
      width: 100,
      ellipsis: {showTitle: false},
      render: (value: string) => value ? <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD')}/> : ''
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 100,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 100,
    },
    {
      title: '附件',
      dataIndex: 'attachments',
      width: 280,
      ellipsis: {showTitle: false},
      render: (value: any) => (
        value?.map((item: any, index: any) => (
          <div key={index}
               style={{
                 border: '1px solid rgb(217,217,217)',
                 padding: '5px',
                 display: 'flex',
                 justifyItems: 'flex-start',
                 alignItems: 'center',
               }}>
            <div style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '80%',
              display: 'flex',
              alignItems: 'center',
            }}>
              <FileZipOutlined style={{paddingRight: '5px'}}/>
              <div style={{cursor: "pointer"}}>
                <RenderEllipsisColumn text={item.fileName}/>
              </div>

            </div>
            <div style={{
              width: '20%',
            }}>
              <Button type="text" onClick={() => onPdfPreview(item.url, item.fileName)} style={{padding: '4px'}}>
                <EyeOutlined/>
              </Button>
              <Button type="link" onClick={() => downloadFile(item.url, item.fileName)} style={{padding: '4px'}}>
                <DownloadOutlined/>
              </Button>
            </div>
          </div>
        ))
      ),
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 200,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType='/car-admin/underwriting-record/edit'
              type="link"
              onClick={() => setOperateInfo({type: 'edit', data: item})}
            >
              编辑
            </PermissionButton>
            <Popconfirm
              title="您确定要删除该条承保记录吗?"
              onConfirm={() => deleteUser(item.id)}
            >
              <PermissionButton operateType='/car-admin/underwriting-record/delete' type="link">删除</PermissionButton>
            </Popconfirm>
            <PermissionButton type="link" onClick={() => setOperateInfo({type: 'policyCancellation', data: item})} operateType='/car-admin/surrender-recode/regress'> 退保 </PermissionButton>
            {/* <Button type="link" onClick={() => setOperateInfo({type: 'changeCancellation', data: item})}> 转保 </Button> */}
            <PermissionButton type="link" onClick={() => setOperateInfo({type: 'policyCancellation', data: item})} operateType='/car-admin/surrender-recode/transfer'> 转保 </PermissionButton>
            <PermissionButton type="link" onClick={() => setOperateInfo({type: 'payinfo', data: item})} operateType='/car-admin/surrender-recode/refund'>服务费还款 </PermissionButton>
            <PermissionButton type="link" onClick={() => onInsureFeeGenerateClick(item)} operateType='/car-admin/surrender-recode/re-split'>重新分期</PermissionButton>
            <PermissionButton type="link" onClick={() => {setOperateInfo({type: 'contractAdd', data: item})}} operateType='/car-admin/surrender-recode/contract'>合同归档</PermissionButton>
          </div>
        )
      },
    },
  ]

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form} layout="vertical" autoComplete='off'>
        <Collapse>
          <Panel header="搜索参数" key="column">
            <Row gutter={24}>
              <Col span={4}>
                <Form.Item label="合同号" name="contractSn">
                  <Input placeholder="请输入合同号" allowClear/>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="保单号" name="commercialNumber">
                  <Input placeholder="请输入保单号" allowClear/>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="承保保司" name="commercialCompany">
                  <Select
                    placeholder="请选择承保保司"
                    options={commercialCompany}
                    allowClear
                    showArrow
                    showSearch
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="服务状态" name="serviceState">
                  <Select
                    placeholder="请选择服务状态"
                    options={serviceState}
                    allowClear
                    showArrow
                    showSearch
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="是否逾期" name="overdueFlag">
                  <Select
                    placeholder="请选择是否逾期"
                    options={overdueFlagData}
                    allowClear
                    showArrow
                    showSearch
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label="起保日期范围" name="commercialBeginDate">
                  <RangePicker allowClear style={{width: '100%'}}/>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="到期日期范围" name="commercialEndDate">
                  <RangePicker allowClear style={{width: '100%'}}/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="省份" name="area">
                  <Select placeholder="请选择省份" options={areaCode} allowClear showArrow showSearch
                          optionFilterProp="label"/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="城市" name="city">
                  <Select placeholder="请选择城市" options={cityCode} allowClear showArrow showSearch
                          optionFilterProp="label"/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="结算状态" name="settlementState">
                  <Select placeholder="请选择结算状态" options={closeFeeState} allowClear showArrow showSearch
                          optionFilterProp="label"/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={4}>
                <Form.Item label="市场归属" name="department">
                  <Input placeholder="请输入市场归属" allowClear/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="渠道经理" name="channelManager">
                  <Input placeholder="请输入渠道经理" allowClear/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="合伙人" name="partner">
                  <Input placeholder="请输入合伙人" allowClear/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="出单人" name="issuer">
                  <Input placeholder="请输入出单人" allowClear/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="车牌号" name="plateNumber">
                  <Input placeholder="请输入车牌号" allowClear onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}}/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="车架号" name="frameNumber">
                  <Input placeholder="请输入车架号" allowClear  onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('frameNumber', newV)}}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={4}>
                <Form.Item label="车辆类型" name="carType">
                  <Select
                    placeholder="请选择车辆类型"
                    options={vehicleType}
                    allowClear
                    showArrow
                    showSearch
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="车辆所有人" name="carOwner">
                  {/* <Input placeholder="请输入车辆所有人" allowClear/> */}
                  <Select options={companyList} placeholder="请选择车辆所有人" showSearch showArrow allowClear ></Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="投保人" name="applicant">
                  <Input placeholder="请输入投保人" allowClear/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="燃料类型" name="fuelType">
                  <Select
                    placeholder="请选择燃料类型"
                    options={fuelType}
                    allowClear
                    showArrow
                    showSearch
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="原保司" name="mainBodyChange">
                  <Select
                    placeholder="请选择原保司"
                    options={commercialCompany}
                    allowClear
                    showArrow
                    showSearch
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="分期类型" name="installmentType">
                  <Select
                    placeholder="请选择分期类型"
                    options={installmentTypeData}
                    allowClear
                    showArrow
                    showSearch
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={3}>
                <Form.Item label="支付状态" name="payState">
                  <Select
                    placeholder="请选择支付状态"
                    options={payStateData}
                    allowClear
                    showArrow
                    showSearch
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="承保模式" name="mode">
                  <Select
                    placeholder="请选择承保模式"
                    options={mode}
                    allowClear
                    showArrow
                    showSearch
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="车辆运维评级" name="operateState">
                  <Select
                    placeholder="车辆运维评级"
                    options={carServeState}
                    allowClear
                    showArrow
                    showSearch
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="是否存在诉讼" name="caseFlag">
                  <Select
                    placeholder="请选择是否存在诉讼"
                    options={caseFlagDataa}
                    allowClear
                    showArrow
                    showSearch
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>
              <Col span={3} style={{marginTop: '20px'}}>
                <Form.Item label="">
                  <Checkbox onChange={onChange}>合同异常</Checkbox>
                  <br/>
                  <Checkbox onChange={onChangee}>当日是否有故障的车辆</Checkbox>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="距下期缴费剩余天数" name="nextPayDays">
                <Select
                    placeholder="距下期缴费剩余天数"
                    options={[
                      {label: '<=0',value: '0'},
                      {label: '<=1',value: '1'},
                      {label: '<=3',value: '3'},
                      {label: '<=7',value: '7'},
                      {label: '<=30',value: '30'}
                    ]}
                    allowClear
                    showArrow
                    showSearch
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="&nbsp;">
                  <div className={styles.searchBox}>
                    <Button type="primary" htmlType="submit">搜索</Button>
                    <Button type="primary" onClick={() => {form.resetFields()}}>重置</Button>
                  </div>
                  
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <div className={styles.operateBox}>
                <ExportButton
                  url={underwritingListRequestPath}
                  fileName='承保记录'
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey='/car-admin/underwriting-record/export'
                />
                <PermissionButton
                  operateType='/car-admin/underwriting-record/add'
                  type="primary"
                  onClick={() => setOperateInfo({type: 'add'})}
                >
                  添加
                </PermissionButton>
                <PermissionButton operateType='/car-admin/underwriting-record/download-template' href="/underwriting_import_demo.xlsx">下载导入模板文件</PermissionButton>
                <UploadExcel
                  uploadRequestPath={importUnderwritingRequestPath}
                  uploadPermissionKey="/car-admin/underwriting-record/import"
                  uploadButtonProps={{children: '批量导入'}}
                  uploadSuccessFunc={() => {
                    message.success('导入成功');
                    getData();
                  }}
                />
                <PermissionButton key="copy" operateType='/car-admin/underwriting-record/copy' onClick={() => {
                  setCopyModal({type: 'copy'})
                  form.setFieldsValue({commercialNumber: null})
                }}>拷贝</PermissionButton>
              </div>
            </Row>

          </Panel>
        </Collapse>
      </Form>

      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
        scroll={{x: '100%', y: '100%'}}
      />
      {['edit', 'add', 'copy'].includes(operateInfo.type) &&
        <OperateModal
          data={operateInfo.data}
          type={operateInfo.type}
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
        />
      }
      {/* 记录支付信息 */}
      {operateInfo.type === 'payinfo' &&
        <PayinfoModal reloadData={getData} data={operateInfo.data} closeModal={() => setOperateInfo({})}/>
      }
      {operateInfo.type === 'policyCancellation' &&
        <PolicyCancellationModal reloadData={getData} data={operateInfo.data} closeModal={() => setOperateInfo({})}/>
      }
      {operateInfo.type === 'changeCancellation' &&
        <ChangeCancellationModal reloadData={getData} data={operateInfo.data} closeModal={() => setOperateInfo({})}/>
      }
      {pdfPreviewInfo && <PdfPreviewModal
        title={pdfPreviewInfo?.fileName}
        pdfUrl={pdfPreviewInfo?.url}
        closeModal={() => setPdfPreviewInfo(null)}
      />}
      {['contractAdd'].includes(operateInfo.type) &&
        <OperateContractModal
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
          type={operateInfo.type}
          data={operateInfo.data}
        />
      }
      {copyModal?.type && <Modal
        title="拷贝承保"
        visible
        centered
        onCancel={() => setCopyModal(null)}
        onOk={() => form.submit()}
        okButtonProps={{loading}}
        maskClosable={false}
      >
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          scrollToFirstError
          onFinish={submit}
        >
          <Form.Item
            label="承保单号"
            name="commercialNumber"
            rules={[
              {required: true, message: '请输入承保单号'}
            ]}
          >
            <Input placeholder="请输入承保单号" allowClear/>
          </Form.Item>
        </Form>
      </Modal>}
    </Card>
  )
}

export default UnderwritingRecord;
