/**
 * 左侧menu
 */
import React, { useState, useEffect } from 'react';
import { Layout, Menu, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import styles from '../index.module.scss';

interface LeftMenuProps {
  collapsed: boolean;
  toggleCollapsed: (value: boolean) => void;
}
const { Sider } = Layout;
const LeftMenu = (props: LeftMenuProps) => {
  const { collapsed, toggleCollapsed } = props;
  const dispatch = useDispatch();
  const userStore = useSelector((state: any) => state.userStore);
  const { menuData, allSubMenuKeys, userDetail } = userStore;
  const tabPagesStore = useSelector((state: any) => state.tabPagesStore);
  const { tabPages, activeKey } = tabPagesStore;
  // const datavUrl = 'https://datav.aliyuncs.com/share/36907f2874b6aad763d1e725bd9bca0c'

  const [openKeys, setOpenKeys] = useState(allSubMenuKeys); // subMenu展开的keys

  // 监听subMenu展开的keys
  useEffect(() => {
    setOpenKeys(allSubMenuKeys);
  }, [allSubMenuKeys])

  // 左侧menu的选中
  const onSelect = (item: any) => {
    const changeKey = item.key;
    if (changeKey === '/car-admin/datav') {
      if (userDetail.datavUrl) {
        // 点击新开页面
        window.open(userDetail.datavUrl);
      } else {
        message.warning('暂无链接');
      }
    } else {
      if (tabPages.find((tabItem: any) => tabItem.key === changeKey)) {
        // 修改激活key
        dispatch({
          type: 'changeActive',
          payload: {
            key: changeKey,
          }
        })
      } else {
        // 添加tab
        dispatch({
          type: 'addTab',
          payload: {
            tab: item.item.props.title,
            key: changeKey,
          }
        })
      }
    }
  }

  // subMenu展开关闭
  const onOpenChange = (openKeys: string[]) => {
    setOpenKeys(openKeys);
  }

  return (
    <Sider
      theme="light"
      collapsible
      collapsed={collapsed}
      className={styles.layoutSider}
      trigger={null}
      breakpoint="lg"
      collapsedWidth="80"
      onBreakpoint={(broken: boolean) => {
        toggleCollapsed(broken)
      }}
    >
      <Menu
        selectedKeys={[activeKey]}
        openKeys={openKeys}
        mode="inline"
        items={menuData}
        className={styles.siderMenu}
        onSelect={onSelect}
        theme="dark"
        onOpenChange={onOpenChange}
      />
    </Sider>
  )
}
export default LeftMenu;