// extracted by mini-css-extract-plugin
export default {"operationGroupManage":"operationGroupManage_operationGroupManage__lIkHL","leftPart":"operationGroupManage_leftPart__WS+JR","buttonBox":"operationGroupManage_buttonBox__06yS5","groupBox":"operationGroupManage_groupBox__R5Qku","groupItem":"operationGroupManage_groupItem__Q4kls","rightPart":"operationGroupManage_rightPart__5ZIj5","title":"operationGroupManage_title__-2-p+","treeBox":"operationGroupManage_treeBox__idQEC","searchBox":"operationGroupManage_searchBox__iYvbX","tree":"operationGroupManage_tree__BdRDF","placeholderBox":"operationGroupManage_placeholderBox__4P-bX"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"operationGroupManage\":\"operationGroupManage_operationGroupManage__lIkHL\",\"leftPart\":\"operationGroupManage_leftPart__WS+JR\",\"buttonBox\":\"operationGroupManage_buttonBox__06yS5\",\"groupBox\":\"operationGroupManage_groupBox__R5Qku\",\"groupItem\":\"operationGroupManage_groupItem__Q4kls\",\"rightPart\":\"operationGroupManage_rightPart__5ZIj5\",\"title\":\"operationGroupManage_title__-2-p+\",\"treeBox\":\"operationGroupManage_treeBox__idQEC\",\"searchBox\":\"operationGroupManage_searchBox__iYvbX\",\"tree\":\"operationGroupManage_tree__BdRDF\",\"placeholderBox\":\"operationGroupManage_placeholderBox__4P-bX\"}";
        // 1734082437002
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  