// extracted by mini-css-extract-plugin
export default {"tags":"commentVideoDetail_tags__Y-hg8","commentTitle":"commentVideoDetail_commentTitle__mVm0t"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"tags\":\"commentVideoDetail_tags__Y-hg8\",\"commentTitle\":\"commentVideoDetail_commentTitle__mVm0t\"}";
        // 1734082449811
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  