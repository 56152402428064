// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","signalItemBox":"signalConfigModal_signalItemBox__7mHKb","signalItemForm":"signalConfigModal_signalItemForm__c5cO2","deleteIcon":"signalConfigModal_deleteIcon__8W-QW"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"signalItemBox\":\"signalConfigModal_signalItemBox__7mHKb\",\"signalItemForm\":\"signalConfigModal_signalItemForm__c5cO2\",\"deleteIcon\":\"signalConfigModal_deleteIcon__8W-QW\"}";
        // 1734082457967
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  