/**
 * 参数配置通用弹窗
 */
import React, { useEffect, useState, useRef, ReactNode } from 'react';
import { Modal, message, Form, Button, Tooltip, FormInstance } from 'antd';
import PermissionButton from '@/components/permissionButton';
import LoadingWrapper from '@/components/loadingWrapper';
import ConfigForm from '@/components/configForm';
import { formatTime } from '@/utils/formatTime';
import { getInnerPermissionEnable } from '@/utils/permission';
import { RequestErrorCode } from '@/constants/requestErrorCode';
import ApproveReasonModal from '@/pages/parameterConfiguration/components/approveReasonModal';

interface ParameterConfigurationModalProps<T> {
  operateParameterValue: any; // 操作参数的标识数据(用于获取参数或配置参数的接口参数)
  operateParameterField: any; // 操作参数的标识字段(用于获取参数或配置参数的接口参数)
  formConfig: any; // 表单配置项
  getParameterRequest?: any; // 获取参数的请求方法
  setParameterRequest: any; // 设置参数的请求方法
  closeModal: () => void; // 关闭弹窗的方法
  modalTitle: string; // 弹窗标题
  modalRightTitle?: ReactNode; // 弹窗右侧标题
  modalWidth?: string | number; // 弹窗宽度
  useCollapse?: boolean; // 是否使用Collapse渲染表单区域
  defaultActiveKeys?: any; // 默认展开的Collapse keys
  configKey?: string; // 配置的权限key
  approveKey?: string; // 需要审批的权限key
  approveType?: string; // 需要审批的审批类型
  isViewParam?: boolean; // 是否只查看参数(用于参数信息的查看)
  setSuccessCallback?: () => void; // 设置参数成功后的回调方法
  labelColSpan?: number; // 表单label的span
  wrapperColSpan?: number; // 表单内容的span
  operateSetFormValueFunc?: (info: T) => void; // 处理读取到的设备信息再赋值给表单
  onValuesChange?: (
    changedValues: any,
    allValues: any,
    form: FormInstance
  ) => void; // 表单项值改变时的方法
  extraFormContent?: ReactNode; // 额外显示的表单内容
  deviceType?: number; // 设备类型
}
function ParameterConfigurationModal<T>(
  props: ParameterConfigurationModalProps<T>
) {
  const {
    operateParameterValue,
    operateParameterField,
    formConfig,
    getParameterRequest,
    setParameterRequest,
    closeModal,
    modalTitle,
    modalRightTitle,
    modalWidth,
    useCollapse,
    defaultActiveKeys,
    configKey,
    approveKey,
    approveType,
    isViewParam = false,
    setSuccessCallback,
    labelColSpan = 14,
    wrapperColSpan = 10,
    operateSetFormValueFunc,
    onValuesChange,
    extraFormContent,
    deviceType
  } = props;
  const [initLoading, setInitLoading] = useState(false); // 获取参数接口的loding
  const [loading, setLoading] = useState(false); // 配置参数接口的loding
  const [deveiceOffline, setDeveiceOffline] = useState(false); // 设备不在线(不在线的设备配置按钮disabled)
  const [offlineInfo, setOfflineInfo] = useState<any>(null); // 离线设备获取到设备参数时的相关信息
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作信息存储
  const [activeKeys, setActiveKeys] =
    useState<Array<string>>(defaultActiveKeys); // Collapse激活的key
  const [form] = Form.useForm();
  const originDataRef = useRef<any>(null); // 存储读取到的设备参数

  useEffect(() => {
    if (getParameterRequest) {
      getParameter();
    } else {
      // 没有获取逻辑时设置sn相关参数
      originDataRef.current = {
        [operateParameterField]: operateParameterValue
      };
    }
  }, []);

  // 获取参数
  const getParameter = async () => {
    try {
      setInitLoading(true);
      const paramIds: any = [];
      formConfig.forEach((item: any) => {
        if (item.paramId) {
          paramIds.push(item.paramId)
        }
      })
      let res: any;
      if (paramIds.length) {
        // 兼容需要传paramIds参数的形式
        res = await getParameterRequest(operateParameterValue, { paramIds: paramIds.join(',') });
      } else {
        res = await getParameterRequest(operateParameterValue);
      }
      // 设备不在线，配置按钮禁用
      if (res.data?.code === RequestErrorCode.deviceOffline) {
        setDeveiceOffline(true);
      } else {
        setDeveiceOffline(false);
      }
      if (res.data?.code === 0) {
        const resData = res.data?.data || {};
        let parameterData: any = {};
        // 兼容需要传paramIds参数返回体结构不同的形式
        if (paramIds.length) {
          paramIds.forEach((item: number) => {
            parameterData = {...parameterData, ...resData[item]}
          })
          parameterData.offline = resData?.offline;
          parameterData.updatedAt = resData?.updatedAt;
        } else {
          parameterData = resData;
        }
        originDataRef.current = {
          ...parameterData,
          [operateParameterField]: operateParameterValue
        };
        form.setFieldsValue(
          operateSetFormValueFunc
            ? operateSetFormValueFunc(parameterData)
            : parameterData
        );
        // 设备不在线获取到设备信息时存储相关信息
        if (parameterData?.offline) {
          setOfflineInfo({ updatedAt: parameterData.updatedAt });
        } else {
          setOfflineInfo(null);
        }
      }
      setInitLoading(false);
    } catch (error) {
      console.log(error);
      setInitLoading(false);
    }
  };

  // 配置参数
  const setParameter = async (params: any) => {
    try {
      setLoading(true);
      const res = await setParameterRequest(params);
      setLoading(false);
      if (res.data?.code === 0) {
        if (setSuccessCallback) {
          setSuccessCallback();
        } else {
          message.success('配置成功');
        }
        closeModal();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // 配置
  const onConfigure = async (values: any) => {
    const needApprove = approveKey && getInnerPermissionEnable(approveKey);
    const params = {
      ...originDataRef.current,
      ...values
    };
    if (needApprove) {
      setOperateInfo({
        type: 'approve',
        data: {
          origin: originDataRef.current,
          target: params,
          type: approveType,
          sn: operateParameterValue,
          deviceType
        }
      });
    } else {
      setParameter(params);
    }
  };

  // 表单校验失败时展开Collapse.Panel进行明显提示
  const onFormFailed = (info: any) => {
    // 使用Collapse校验失败展开Collapse.Panel
    if (useCollapse) {
      const { errorFields } = info;
      const errorFieldsNames = errorFields
        .map((item: any) => item.name)
        .flat(Infinity);
      const panelKeys: Array<string> = [];
      // 获取各个Collapse.Panel下对应的form表单
      formConfig.forEach((item: any) => {
        const { panelKey, formItems } = item;
        formItems.forEach((item1: any) => {
          item1.forEach((item2: any) => {
            if (errorFieldsNames.includes(item2.name)) {
              panelKeys.push(panelKey);
            }
          });
        });
      });
      setActiveKeys(panelKeys.flat(Infinity));
      form.scrollToField(panelKeys[0]);
    }
  };

  // 渲染弹窗的title
  const renderModalTitle = () => {
    const offlineStr = offlineInfo ? (
      <span className="dangerText">
        （离线数据，数据时间：
        {formatTime(offlineInfo?.updatedAt)}）
      </span>
    ) : (
      ''
    );
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingRight: '24px'
        }}
      >
        <span>
          {modalTitle}
          {offlineStr}
        </span>
        {modalRightTitle}
      </div>
    );
  };

  // Collapse收起展开
  const onCollapseChange = (keys: any) => {
    setActiveKeys(keys);
  };

  return (
    <Modal
      title={renderModalTitle()}
      open
      onCancel={closeModal}
      centered
      width={modalWidth || '80%'}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          取消
        </Button>,
        // 没有获取参数的方法时不显示读取按钮
        Boolean(getParameterRequest) && (
          <Button
            type="primary"
            key="read"
            onClick={getParameter}
            loading={initLoading}
          >
            读取
          </Button>
        ),
        // 用作参数信息查看时不显示配置按钮
        isViewParam || (
          <Tooltip
            title={
              deveiceOffline
                ? '当前设备不在线，可尝试重新读取设备参数信息。'
                : ''
            }
            key="ok"
          >
            <span style={{ marginLeft: '8px' }}>
              <PermissionButton
                operateType={configKey}
                type="primary"
                onClick={() => form.submit()}
                loading={loading}
                disabled={deveiceOffline || initLoading || offlineInfo}
              >
                配置
              </PermissionButton>
            </span>
          </Tooltip>
        )
      ]}
      // 用作参数信息查看时点击浮层可以关闭弹窗
      maskClosable={isViewParam}
    >
      <LoadingWrapper spinning={initLoading}>
        <Form
          form={form}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          onFinish={onConfigure}
          onFinishFailed={onFormFailed}
          labelWrap
          scrollToFirstError
          // 用作参数信息查看时表单禁用
          disabled={isViewParam}
          onValuesChange={
            onValuesChange
              ? (changedValues, allValues) =>
                  onValuesChange(changedValues, allValues, form)
              : undefined
          }
        >
          {extraFormContent}
          <ConfigForm
            formConfig={formConfig}
            form={form}
            originData={originDataRef.current}
            showCollapse={useCollapse}
            activeKeys={activeKeys}
            onCollapseChange={onCollapseChange}
          />
        </Form>
      </LoadingWrapper>
      {/* 填写审批原因的弹窗 */}
      {operateInfo.type === 'approve' && (
        <ApproveReasonModal
          {...operateInfo.data}
          closeModal={() => setOperateInfo({})}
          successCallback={closeModal}
        />
      )}
    </Modal>
  );
}

export default ParameterConfigurationModal;
