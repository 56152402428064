// extracted by mini-css-extract-plugin
export default {"layout":"tabPages_layout__3qBLT","layoutContent":"tabPages_layoutContent__wRw4-","layoutSider":"tabPages_layoutSider__I3eeI","siderMenu":"tabPages_siderMenu__tEKYM","mainContentWrapper":"tabPages_mainContentWrapper__4amKc","mainContent":"tabPages_mainContent__FVSYV"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"layout\":\"tabPages_layout__3qBLT\",\"layoutContent\":\"tabPages_layoutContent__wRw4-\",\"layoutSider\":\"tabPages_layoutSider__I3eeI\",\"siderMenu\":\"tabPages_siderMenu__tEKYM\",\"mainContentWrapper\":\"tabPages_mainContentWrapper__4amKc\",\"mainContent\":\"tabPages_mainContent__FVSYV\"}";
        // 1734082414319
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  