/**
 * 承保记录相关请求
 */
import request from "@/utils/request";

export interface UnderwritingListParams {
  beginDate?: string;
  endDate?: string;
  page: number;
  limit: number;
  plateNumber?: string;
  frameNumber?: string;
  contractSn?: any;
  commercialNumber?: any;
  commercialCompany?: any;
  commercialBeginDate?: any;
  commercialEndDate?: any;
  area?: any;
  city?: any;
  department?: any;
  channelManager?: any;
  partner?: any;
  issuer?: any;
  carType?: any;
  fuelType?: any;
  carOwner?: any;
  applicant?: any;
  mainBodyChange?: any;
  installmentType?: any;
  payState?: any;
  payDate?: any;
  operateState?: any;
  mode?: any;
  caseFlag?: any;
  accidentFlag?: any;
  serviceState?: any;
}

export interface InsureFeeListParams {
  insuranceCode?: any;
}

// 获取承保记录列表数据
export const underwritingListRequestPath = "/api/v2/underwriting/v1/list";
export const underwritingListRequest = (params: UnderwritingListParams) => {
  return request.get(underwritingListRequestPath, {
    params,
  });
};

// 添加或编辑承保记录
export const upsertUnderwritingRequest = (data: any) => {
  return request.post("/api/v2/underwriting/v1/upsert", data);
};

// 删除承保记录
export const deleteUnderwritingRequest = (id: number) => {
  return request.delete(`/api/v2/underwriting/${id}`);
};

// 批量导入的接口路径
export const importUnderwritingRequestPath = "/api/v2/underwriting/import";

//获取 承保单分期记录信息
export const insureFeeListRequest = (params: InsureFeeListParams) => {
  return request.get("/api/v2/insure/fee/list", {
    params,
  });
};

// 更新承保但分期记录信息
export const upsertInsureFeeRequest = (data: any) => {
  return request.post("/api/v2/insure/fee/v1/upsert", data);
};

//省
export const getProvincialListRequest = () => {
  return request.get("/api/v2/city/list/provincial");
};

//市
export const getCityListRequest = () => {
  return request.get("/api/v2/city/list/city");
};

//转保  
export const changeUnderwritingRequest = (data: any) => {
  return request.post("/api/v2/underwriting/change", data);
};

//更新退保  
export const surrenderUnderwritingRequestPath = '/api/v2/underwriting/surrender'
export const surrenderUnderwritingRequest = (data: any) => {
  return request.post(surrenderUnderwritingRequestPath, data);
};

//退保记录
export const surrenderRecordListRequestPath = "/api/v2/insure/surrender/record/list";
export const surrenderRecordListRequest = (params:any) => {
  return request.get(surrenderRecordListRequestPath, {
    params,
  });
};

//查询支付
export const payRecordListRequest = (params:any) => {
  return request.get("/api/v2/insure/pay/record/list", {
    params,
  });
};

//更新支付记录
export const upsertInsurePayRequestPath = '/api/v2/insure/pay/record/upsert'
export const upsertInsurePayRequest = (data: any) => {
  return request.post(upsertInsurePayRequestPath, data);
};

// 清楚结算
export const clearSettlement = 'api/v2/insure/pay/record/clear/settlement'
export const clearSettlementRequest = (data: any) => {
  return request.post(clearSettlement, data);
};

export const batchClear = 'api/v2/insure/pay/record/batch/settlement'
export const batchClearRequest = (data: any) => {
  return request.post(batchClear, data);
}

//更新支付记录
export const payRecordRequestPath = 'api/v2/insure/pay/record'
export const payRecordRequest = (data: any) => {
  return request.post(payRecordRequestPath, data);
};

//删除支付记录
export const deleteInsurePayRequest = (id: number) => {
  return request.delete(`/api/v2/insure/pay/record/${id}`);
};

// 拷贝承保数据
export const underwritingDetailRequestPath = "/api/v2/underwriting/detail";
export const underwritingDetailRequest = (params:any) => {
  return request.get(underwritingDetailRequestPath, {
    params,
  });
};


//更新分期
export const upsertInsureFeeGenerateRequestPath = '/api/v2/underwriting/insureFee/generate'
export const upsertInsureFeeGenerateRequest = (data: any) => {
  return request.post(upsertInsureFeeGenerateRequestPath, data);
};
