// extracted by mini-css-extract-plugin
export default {"loginBg":"login_loginBg__3+XK9","icp":"login_icp__NXQFh","loginBox":"login_loginBox__KZ1oI","logo":"login_logo__qSwSn","loginForm":"login_loginForm__uK3yo","title":"login_title__WMp+n","desc":"login_desc__W9hqS"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"loginBg\":\"login_loginBg__3+XK9\",\"icp\":\"login_icp__NXQFh\",\"loginBox\":\"login_loginBox__KZ1oI\",\"logo\":\"login_logo__qSwSn\",\"loginForm\":\"login_loginForm__uK3yo\",\"title\":\"login_title__WMp+n\",\"desc\":\"login_desc__W9hqS\"}";
        // 1734082413771
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  