// extracted by mini-css-extract-plugin
export default {"layoutHeader":"mainHeader_layoutHeader__Br9bJ","checkBox":"mainHeader_checkBox__6n-j+","logoTitle":"mainHeader_logoTitle__17uuN","headerContent":"mainHeader_headerContent__MDCVg","toggleIcon":"mainHeader_toggleIcon__4UoDM","quickMenu":"mainHeader_quickMenu__9p3zK","userName":"mainHeader_userName__EKKw5","informationText":"mainHeader_informationText__CncPL","informationBox":"mainHeader_informationBox__IpHpe","information":"mainHeader_information__XFX1x","informationBoxReadAll":"mainHeader_informationBoxReadAll__nJzka","informationBoxItem":"mainHeader_informationBoxItem__0AX+E","informationBoxCenter":"mainHeader_informationBoxCenter__4hd1v","informationBoxButton":"mainHeader_informationBoxButton__kWUHp","informationBoxButtonItem":"mainHeader_informationBoxButtonItem__O3egk","messageCard":"mainHeader_messageCard__p7+sl","informationNum":"mainHeader_informationNum__dKa0M","menuBox":"mainHeader_menuBox__TI8eJ","menuGroup":"mainHeader_menuGroup__94rzB","menuTitle":"mainHeader_menuTitle__X0LQV","menuItemGroup":"mainHeader_menuItemGroup__spoRp","menuItem":"mainHeader_menuItem__TtCU0"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"layoutHeader\":\"mainHeader_layoutHeader__Br9bJ\",\"checkBox\":\"mainHeader_checkBox__6n-j+\",\"logoTitle\":\"mainHeader_logoTitle__17uuN\",\"headerContent\":\"mainHeader_headerContent__MDCVg\",\"toggleIcon\":\"mainHeader_toggleIcon__4UoDM\",\"quickMenu\":\"mainHeader_quickMenu__9p3zK\",\"userName\":\"mainHeader_userName__EKKw5\",\"informationText\":\"mainHeader_informationText__CncPL\",\"informationBox\":\"mainHeader_informationBox__IpHpe\",\"information\":\"mainHeader_information__XFX1x\",\"informationBoxReadAll\":\"mainHeader_informationBoxReadAll__nJzka\",\"informationBoxItem\":\"mainHeader_informationBoxItem__0AX+E\",\"informationBoxCenter\":\"mainHeader_informationBoxCenter__4hd1v\",\"informationBoxButton\":\"mainHeader_informationBoxButton__kWUHp\",\"informationBoxButtonItem\":\"mainHeader_informationBoxButtonItem__O3egk\",\"messageCard\":\"mainHeader_messageCard__p7+sl\",\"informationNum\":\"mainHeader_informationNum__dKa0M\",\"menuBox\":\"mainHeader_menuBox__TI8eJ\",\"menuGroup\":\"mainHeader_menuGroup__94rzB\",\"menuTitle\":\"mainHeader_menuTitle__X0LQV\",\"menuItemGroup\":\"mainHeader_menuItemGroup__spoRp\",\"menuItem\":\"mainHeader_menuItem__TtCU0\"}";
        // 1734082437499
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  