// extracted by mini-css-extract-plugin
export default {"carDiagnosis":"carSitution_carDiagnosis__4q035","searchBox":"carSitution_searchBox__2hW4p","carInfoBox":"carSitution_carInfoBox__iKBz9","carInfoTitle":"carSitution_carInfoTitle__lA2cF","carInfoContent":"carSitution_carInfoContent__jJiHZ","carInfoPerson":"carSitution_carInfoPerson__EVblR","carInfoDriver":"carSitution_carInfoDriver__HZVM3","personPhone":"carSitution_personPhone__SNrJ0","driverPhone":"carSitution_driverPhone__lgYzo","carMap":"carSitution_carMap__lAZNP"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"carDiagnosis\":\"carSitution_carDiagnosis__4q035\",\"searchBox\":\"carSitution_searchBox__2hW4p\",\"carInfoBox\":\"carSitution_carInfoBox__iKBz9\",\"carInfoTitle\":\"carSitution_carInfoTitle__lA2cF\",\"carInfoContent\":\"carSitution_carInfoContent__jJiHZ\",\"carInfoPerson\":\"carSitution_carInfoPerson__EVblR\",\"carInfoDriver\":\"carSitution_carInfoDriver__HZVM3\",\"personPhone\":\"carSitution_personPhone__SNrJ0\",\"driverPhone\":\"carSitution_driverPhone__lgYzo\",\"carMap\":\"carSitution_carMap__lAZNP\"}";
        // 1734082437648
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  