// extracted by mini-css-extract-plugin
export default {"dataBox":"dataSizeDetails_dataBox__G1xeo","dataBoxLittleTitle":"dataSizeDetails_dataBoxLittleTitle__0WZIv","dataBoxTitle":"dataSizeDetails_dataBoxTitle__ZIxnT","dataBoxContent":"dataSizeDetails_dataBoxContent__r65EY","dataBoxContentLeft":"dataSizeDetails_dataBoxContentLeft__2bFjV","leftChartOne":"dataSizeDetails_leftChartOne__KAEDe","dataBoxContentLeftItemTitle":"dataSizeDetails_dataBoxContentLeftItemTitle__vhFXJ","dataBoxContentLeftList":"dataSizeDetails_dataBoxContentLeftList__mw644","dataBoxContentLeftNone":"dataSizeDetails_dataBoxContentLeftNone__5BAOM","dataBoxContentLeftItem":"dataSizeDetails_dataBoxContentLeftItem__w+ngh","dataBoxContentLeftItemLeft":"dataSizeDetails_dataBoxContentLeftItemLeft__ODoH6","dataBoxContentLeftItemMiddle":"dataSizeDetails_dataBoxContentLeftItemMiddle__dfcP4","dataBoxContentLeftItemRight":"dataSizeDetails_dataBoxContentLeftItemRight__pdGXG","dataBoxContentMiddle":"dataSizeDetails_dataBoxContentMiddle__lP5NB","dataBoxContentMiddleTitle":"dataSizeDetails_dataBoxContentMiddleTitle__LrCEu","mapBox":"dataSizeDetails_mapBox__tCihF","dataBoxContentRight":"dataSizeDetails_dataBoxContentRight__A1oIG","rightChartOne":"dataSizeDetails_rightChartOne__ZUupi","rightChartTwo":"dataSizeDetails_rightChartTwo__pA0Re","rightChartThree":"dataSizeDetails_rightChartThree__PziD6"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"dataBox\":\"dataSizeDetails_dataBox__G1xeo\",\"dataBoxLittleTitle\":\"dataSizeDetails_dataBoxLittleTitle__0WZIv\",\"dataBoxTitle\":\"dataSizeDetails_dataBoxTitle__ZIxnT\",\"dataBoxContent\":\"dataSizeDetails_dataBoxContent__r65EY\",\"dataBoxContentLeft\":\"dataSizeDetails_dataBoxContentLeft__2bFjV\",\"leftChartOne\":\"dataSizeDetails_leftChartOne__KAEDe\",\"dataBoxContentLeftItemTitle\":\"dataSizeDetails_dataBoxContentLeftItemTitle__vhFXJ\",\"dataBoxContentLeftList\":\"dataSizeDetails_dataBoxContentLeftList__mw644\",\"dataBoxContentLeftNone\":\"dataSizeDetails_dataBoxContentLeftNone__5BAOM\",\"dataBoxContentLeftItem\":\"dataSizeDetails_dataBoxContentLeftItem__w+ngh\",\"dataBoxContentLeftItemLeft\":\"dataSizeDetails_dataBoxContentLeftItemLeft__ODoH6\",\"dataBoxContentLeftItemMiddle\":\"dataSizeDetails_dataBoxContentLeftItemMiddle__dfcP4\",\"dataBoxContentLeftItemRight\":\"dataSizeDetails_dataBoxContentLeftItemRight__pdGXG\",\"dataBoxContentMiddle\":\"dataSizeDetails_dataBoxContentMiddle__lP5NB\",\"dataBoxContentMiddleTitle\":\"dataSizeDetails_dataBoxContentMiddleTitle__LrCEu\",\"mapBox\":\"dataSizeDetails_mapBox__tCihF\",\"dataBoxContentRight\":\"dataSizeDetails_dataBoxContentRight__A1oIG\",\"rightChartOne\":\"dataSizeDetails_rightChartOne__ZUupi\",\"rightChartTwo\":\"dataSizeDetails_rightChartTwo__pA0Re\",\"rightChartThree\":\"dataSizeDetails_rightChartThree__PziD6\"}";
        // 1734082451892
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  