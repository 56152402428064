/**
 * 总表格
 */
import React, { useEffect, useState, Fragment } from 'react';
import { Button, Timeline } from 'antd';
import CommonTable from '@/components/commonTable';
import SomeOneTableModal from '../someOneTableModal';
import ChartModal from '../chartModal';
import { questionnaireSuggestionPath, questionnaireSuggestionRequest } from '@/service/questionnaireSurveyTotal';
import ExportButton from '@/components/exportButton';

interface TotalTableProps {
  searchParams: any; // 搜索相关的参数
  requestMethod: any; // 请求方法
  tableName?: any; // 名字
  exportPath?: any; // 导出
}
const TotalTable = (props: TotalTableProps) => {
  const { searchParams, requestMethod, tableName, exportPath } = props;
  const [tableData, setTableData] = useState<any>([]); // 表格要显示的数据
  const [loading, setLoading] = useState(false);
  const [chartVisible, setChartVisible] = useState(false); // 图表的显示控制
  const [someOneData, setSomeOneData] = useState<any>(null); // 弹窗的相关数据
  const [suggestion, setSuggestion] = useState<any>([]); // 建议

  useEffect(() => {
    getData({
      ...searchParams,
    });
  }, [searchParams]);

  // 获取数据
  const getData = async (params: any) => {
    try {
      setLoading(true);
      if (tableName == 'questionItem') {
        const res = await requestMethod(params);
        setTableData(res.data?.data)
        const resss = await questionnaireSuggestionRequest(params);
        const data: any = resss.data.data || [];
        setSuggestion(data)
        setLoading(false);
      } else {
        const res = await requestMethod(params);
        const data: any = res.data.data || [];
        setTableData(data)
        setLoading(false);
      }
      if (tableName == 'questionItem') {
        
      }
    } catch (error) {
      setLoading(false);
    }
  }

  // 表格列显示
  const columns: any = [
    {
      title: '车队',
      dataIndex: 'name',
      width: 100,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <div style={{color:'#1890ff', cursor:'pointer'}} onClick={() => setSomeOneData({ type: 'company', name: value})}>{value}</div>
      ),
    },
    {
      title: '车辆',
      dataIndex: 'name',
      width: 100,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <div style={{color:'#1890ff', cursor:'pointer'}} onClick={() => setSomeOneData({ type: 'plateNumber', name: value})}>{value}</div>
      ),
    },
    {
      title: '安装人员',
      dataIndex: 'name',
      width: 100,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <div style={{color:'#1890ff', cursor:'pointer'}} onClick={() => setSomeOneData({ type: 'maintainer', name: value})}>{value}</div>
      ),
    },
    {
      title: '评分',
      dataIndex: 'score',
      width: 100,
      ellipsis: { showTitle: false },
      render: (value: any) => Number(value).toFixed(2)
    },
    {
      title: '调研项',
      dataIndex: 'name',
      width: 500,
      ellipsis: { showTitle: false },
    },
    {
      title: '是',
      dataIndex: 'yesFlag',
      width: 100,
      ellipsis: { showTitle: false },
    },
    {
      title: '否',
      dataIndex: 'noFlag',
      width: 100,
      ellipsis: { showTitle: false },
    },
  ];

  if (tableName == 'company') {
    columns.splice(1,2);
    columns.splice(2);
  } else if (tableName == 'plateNumber') {
    columns.splice(0,1);
    columns.splice(1,1);
    columns.splice(2);
  } else if (tableName == 'maintainer') {
    columns.splice(0,2);
    columns.splice(2,2);
    columns.splice(2);
  } else if (tableName == 'questionItem') {
    columns.splice(0,4);
  }

  // 获取导出的参数
  const getExportParams = () => {
    const values = searchParams;
    return operateSearchParams(values, true);
  }

  // 处理搜索参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newSearchParams:any = {...params}
    if (isExport) {
      newSearchParams.export = true;
    }
    return newSearchParams;
  }

  return (
    <Fragment>
      <div style={{ position: 'relative' }}>
        {tableName !== 'questionItem' ? <div style={{ textAlign: 'right', margin: '12px 0', zIndex: 99, position:'absolute', right:'100px' }}>
          <Button type='primary' onClick={() => setChartVisible(true)}>显示图表趋势</Button>
        </div> : null}
        <ExportButton 
          style={{ textAlign: 'right', margin: '12px 0', zIndex: 99, position:'absolute', right:'10px' }}
          url={exportPath}
          fileName='调查汇总'
          getParamsFunc={getExportParams}
          requestMethod="GET"
          permissionKey='/car-admin/service-task/export'
        />
        <CommonTable
          columnFilter={false}
          columns={columns}
          dataSource={tableData}
          pagination={false}
          bordered
          loading={loading}
        />
        {(tableName == 'questionItem'&&suggestion?.length>0) ? <Timeline style={{ marginTop: '24px', position: 'relative' }}>
          <ExportButton 
            style={{ textAlign: 'right', margin: '12px 0', zIndex: 99, position:'absolute', right:'10px',top:'-20px' }}
            url={questionnaireSuggestionPath}
            fileName='调查汇总'
            getParamsFunc={getExportParams}
            requestMethod="GET"
            permissionKey='/car-admin/service-task/export'
          />
          {(suggestion || []).map((item: any) => (
            <Timeline.Item>
              <div>建议或者意见：{item.name}</div>
            </Timeline.Item>
          ))}
        </Timeline> : null}
      </div>
      {someOneData &&
        <SomeOneTableModal
          data={someOneData}
          closeModal={() => setSomeOneData(null)}
          searchParams={searchParams}
        />
      }
      {chartVisible &&
        <ChartModal
          data={tableName}
          closeModal={() => setChartVisible(false)}
          searchParams={searchParams}
        />
      }
    </Fragment>
  )
}

export default TotalTable;