// extracted by mini-css-extract-plugin
export default {"reportBox":"offerManage_reportBox__FjcYG","searchBox":"offerManage_searchBox__1uA4d","analysisMap":"offerManage_analysisMap__F3p3s","innerTitle":"offerManage_innerTitle__tPV+K","tableOperateColumn":"offerManage_tableOperateColumn__NNK15"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"reportBox\":\"offerManage_reportBox__FjcYG\",\"searchBox\":\"offerManage_searchBox__1uA4d\",\"analysisMap\":\"offerManage_analysisMap__F3p3s\",\"innerTitle\":\"offerManage_innerTitle__tPV+K\",\"tableOperateColumn\":\"offerManage_tableOperateColumn__NNK15\"}";
        // 1734082437438
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  