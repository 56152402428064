// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","liveVideoPlaceholder":"liveVideo_liveVideoPlaceholder__XWxfa","liveVideo":"liveVideo_liveVideo__k+4mV","toolbar":"liveVideo_toolbar__Skxfh","video":"liveVideo_video__2DB17"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"liveVideoPlaceholder\":\"liveVideo_liveVideoPlaceholder__XWxfa\",\"liveVideo\":\"liveVideo_liveVideo__k+4mV\",\"toolbar\":\"liveVideo_toolbar__Skxfh\",\"video\":\"liveVideo_video__2DB17\"}";
        // 1734082451197
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  