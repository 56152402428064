// extracted by mini-css-extract-plugin
export default {"carGroupMoveBox":"carGroupMove_carGroupMoveBox__-23-S","carTree":"carGroupMove_carTree__6UDD4","moveButtonBox":"carGroupMove_moveButtonBox__grUuK","rightPart":"carGroupMove_rightPart__Zios-","carBoxTitle":"carGroupMove_carBoxTitle__MjxWD","carBox":"carGroupMove_carBox__qYe2v","carItem":"carGroupMove_carItem__tMSSL"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"carGroupMoveBox\":\"carGroupMove_carGroupMoveBox__-23-S\",\"carTree\":\"carGroupMove_carTree__6UDD4\",\"moveButtonBox\":\"carGroupMove_moveButtonBox__grUuK\",\"rightPart\":\"carGroupMove_rightPart__Zios-\",\"carBoxTitle\":\"carGroupMove_carBoxTitle__MjxWD\",\"carBox\":\"carGroupMove_carBox__qYe2v\",\"carItem\":\"carGroupMove_carItem__tMSSL\"}";
        // 1734082436962
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  