// extracted by mini-css-extract-plugin
export default {"tagManageBox":"tagManage_tagManageBox__R-3eo","operateBox":"tagManage_operateBox__13zjp","tagBox":"tagManage_tagBox__vuLOY","sortTree":"tagManage_sortTree__EbYki","treeTitle":"tagManage_treeTitle__IIJc0","tagList":"tagManage_tagList__DuzP5"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"tagManageBox\":\"tagManage_tagManageBox__R-3eo\",\"operateBox\":\"tagManage_operateBox__13zjp\",\"tagBox\":\"tagManage_tagBox__vuLOY\",\"sortTree\":\"tagManage_sortTree__EbYki\",\"treeTitle\":\"tagManage_treeTitle__IIJc0\",\"tagList\":\"tagManage_tagList__DuzP5\"}";
        // 1734082436809
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  