// extracted by mini-css-extract-plugin
export default {"toggleBox":"alarmPush_toggleBox__T8MXQ","icon":"alarmPush_icon__cq7-0","text":"alarmPush_text__9YR46","count":"alarmPush_count__l7+D3","pushBox":"alarmPush_pushBox__Qcjlo","alarmBox":"alarmPush_alarmBox__9C-Ka","alarmItem":"alarmPush_alarmItem__TCEOT","title":"alarmPush_title__e-dsM","time":"alarmPush_time__7MLoT","clear":"alarmPush_clear__Sm2fS"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"toggleBox\":\"alarmPush_toggleBox__T8MXQ\",\"icon\":\"alarmPush_icon__cq7-0\",\"text\":\"alarmPush_text__9YR46\",\"count\":\"alarmPush_count__l7+D3\",\"pushBox\":\"alarmPush_pushBox__Qcjlo\",\"alarmBox\":\"alarmPush_alarmBox__9C-Ka\",\"alarmItem\":\"alarmPush_alarmItem__TCEOT\",\"title\":\"alarmPush_title__e-dsM\",\"time\":\"alarmPush_time__7MLoT\",\"clear\":\"alarmPush_clear__Sm2fS\"}";
        // 1734082414070
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  