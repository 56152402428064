// extracted by mini-css-extract-plugin
export default {"onLineRecordBox":"onlineRecord_onLineRecordBox__FkZXx","carTree":"onlineRecord_carTree__0yDca","recordBox":"onlineRecord_recordBox__hZh1K","tips":"onlineRecord_tips__oB0Gq","month":"onlineRecord_month__MZqIT","monthItemBox":"onlineRecord_monthItemBox__JJjuJ","monthItem":"onlineRecord_monthItem__I8iJd","title":"onlineRecord_title__ZZUQL","week":"onlineRecord_week__lXm-W","weekItem":"onlineRecord_weekItem__172GW","day":"onlineRecord_day__zcX5E","dayItem":"onlineRecord_dayItem__z7Uu4","dayItemOnline":"onlineRecord_dayItemOnline__cGg2j"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"onLineRecordBox\":\"onlineRecord_onLineRecordBox__FkZXx\",\"carTree\":\"onlineRecord_carTree__0yDca\",\"recordBox\":\"onlineRecord_recordBox__hZh1K\",\"tips\":\"onlineRecord_tips__oB0Gq\",\"month\":\"onlineRecord_month__MZqIT\",\"monthItemBox\":\"onlineRecord_monthItemBox__JJjuJ\",\"monthItem\":\"onlineRecord_monthItem__I8iJd\",\"title\":\"onlineRecord_title__ZZUQL\",\"week\":\"onlineRecord_week__lXm-W\",\"weekItem\":\"onlineRecord_weekItem__172GW\",\"day\":\"onlineRecord_day__zcX5E\",\"dayItem\":\"onlineRecord_dayItem__z7Uu4\",\"dayItemOnline\":\"onlineRecord_dayItemOnline__cGg2j\"}";
        // 1734082424075
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  