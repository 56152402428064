// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","pageContentBox":"pageContent_pageContentBox__TZCKo","fixPageContentBox":"pageContent_fixPageContentBox__J8n7R","fixPageTabContentBox":"pageContent_fixPageTabContentBox__ZJ490","fixModalTab":"pageContent_fixModalTab__cwVh1","searchBox":"pageContent_searchBox__xBc8n","operateBox":"pageContent_operateBox__7zT4r","tableTagColumn":"pageContent_tableTagColumn__VTdGq","operateTextButton":"pageContent_operateTextButton__KZPW5","tableOperateColumn":"pageContent_tableOperateColumn__sE4dt","scrollMoreTableFooter":"pageContent_scrollMoreTableFooter__qSxtS","desc":"pageContent_desc__1tS+b","spaceBetweenFlex":"pageContent_spaceBetweenFlex__gY-+s","spaceBetweenPageContentBox":"pageContent_spaceBetweenPageContentBox__Ci9iJ","carTree":"pageContent_carTree__OoHux","contentBox":"pageContent_contentBox__+NGLe","searchButtonBox":"pageContent_searchButtonBox__-p4Im","tipBox":"pageContent_tipBox__KshYs","tableBox":"pageContent_tableBox__+RHgh","drawerTableBox":"pageContent_drawerTableBox__Xtdzq","commonTableWrapper":"pageContent_commonTableWrapper__yaQC6"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"pageContentBox\":\"pageContent_pageContentBox__TZCKo\",\"fixPageContentBox\":\"pageContent_fixPageContentBox__J8n7R\",\"fixPageTabContentBox\":\"pageContent_fixPageTabContentBox__ZJ490\",\"fixModalTab\":\"pageContent_fixModalTab__cwVh1\",\"searchBox\":\"pageContent_searchBox__xBc8n\",\"operateBox\":\"pageContent_operateBox__7zT4r\",\"tableTagColumn\":\"pageContent_tableTagColumn__VTdGq\",\"operateTextButton\":\"pageContent_operateTextButton__KZPW5\",\"tableOperateColumn\":\"pageContent_tableOperateColumn__sE4dt\",\"scrollMoreTableFooter\":\"pageContent_scrollMoreTableFooter__qSxtS\",\"desc\":\"pageContent_desc__1tS+b\",\"spaceBetweenFlex\":\"pageContent_spaceBetweenFlex__gY-+s\",\"spaceBetweenPageContentBox\":\"pageContent_spaceBetweenPageContentBox__Ci9iJ\",\"carTree\":\"pageContent_carTree__OoHux\",\"contentBox\":\"pageContent_contentBox__+NGLe\",\"searchButtonBox\":\"pageContent_searchButtonBox__-p4Im\",\"tipBox\":\"pageContent_tipBox__KshYs\",\"tableBox\":\"pageContent_tableBox__+RHgh\",\"drawerTableBox\":\"pageContent_drawerTableBox__Xtdzq\",\"commonTableWrapper\":\"pageContent_commonTableWrapper__yaQC6\"}";
        // 1734082425007
        var cssReload = require("../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  