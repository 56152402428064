/**
 * 直播播放器
 */
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import Player, { Events } from 'xgplayer';
import FlvJsPlugin from 'xgplayer-flv.js';
import { message } from 'antd';
import {
  VideoCameraOutlined,
  ExpandOutlined,
  CloseOutlined,
  RedoOutlined
} from '@ant-design/icons';
import {
  jt808Command9101Request,
  onlineChannelListRequest
} from '@/service/jtDeviceMonitor';
import localStorageUtil, {
  LocalStorageKeyEnum
} from '@/utils/localStorageUtil';
import { operateJt808VersionPath } from '@/utils/jtDeviceRequest';
import { ownRound } from '@/utils/math';
import LoadingWrapper from '@/components/loadingWrapper';
import styles from './index.module.scss';

interface LiveVideoProps {
  jt808DeviceId?: string; // 部标机设备ID
  channel?: string | number; // 通道
  terminalPhone?: string; // 终端手机号
  version?: string; // 808版本
  plateNumber?: string; // 车牌号
  closeWindow?: Function; // 关闭播放器的方法
  style?: CSSProperties; // 样式
  useInRealTimeMonitor?: boolean; // 是否在实时监控页面使用(有定时暂停逻辑)
  videoConfig?: any; // 播放器的配置信息
}
const LiveVideo = (props: LiveVideoProps) => {
  const {
    jt808DeviceId,
    channel,
    terminalPhone,
    version,
    plateNumber,
    closeWindow,
    style,
    useInRealTimeMonitor = false,
    videoConfig
  } = props;
  const videoDomRef = useRef<any>(null); // 播放器DOM
  const playerRef = useRef<any>(null); // 播放器
  const timerRef = useRef<any>(null); // 视频定时停止定时器
  const speedTimerRef = useRef<any>(null); // 定时获取下载速度的定时器
  const isPlayRef = useRef<boolean>(true); // 当前播放器是否在播放状态
  const [speed, setSpeed] = useState(''); // 下载速度
  const [startDeviceLiveLoading, setStartDeviceLiveLoading] = useState(false); // 向设备下发直播指令的loading
  const [startDeviceLiveError, setStartDeviceLiveError] = useState(''); // 向设备下发直播指令的错误信息

  // 清空视频定时停止定时器和定时获取下载速度的定时器
  const clearTimeRef = () => {
    setSpeed('');
    timerRef.current && clearTimeout(timerRef.current);
    timerRef.current = null;
    speedTimerRef.current && clearTimeout(speedTimerRef.current);
    speedTimerRef.current = null;
  };

  // 销毁播放器
  const destroyVideo = () => {
    clearTimeRef();
    setStartDeviceLiveError('');
    if (playerRef.current) {
      playerRef.current.destroy();
    }
    playerRef.current = null;
  };

  useEffect(() => {
    return () => {
      destroyVideo();
    };
  }, []);

  // 获取下载速度
  const getSpeedInfo = () => {
    if (playerRef.current.plugins.flvjsplugin.flv) {
      const { speed = 0 } = playerRef.current.plugins.flvjsplugin.flv.statisticsInfo || {};
      setSpeed(`${ownRound(speed, 0)}KB/s`);
    }
    speedTimerRef.current = setTimeout(() => {
      getSpeedInfo();
    }, 1000);
  };

  // 获取实时监控页面参数设置
  const getMonitorSetting = () => {
    if (useInRealTimeMonitor) {
      const realTimeMonitorSetting = localStorageUtil.getItem(
        LocalStorageKeyEnum.real_time_monitor_setting,
        {}
      );
      const { codeStream = 1, autocCloseVideoTime = 3 } =
        realTimeMonitorSetting;
      return { codeStream, autocCloseVideoTime };
    } else {
      return { codeStream: 1, autocCloseVideoTime: 0 };
    }
  };

  // 向设备下发直播指令
  const startDeviceLive = async () => {
    try {
      setStartDeviceLiveLoading(true);
      const { codeStream } = getMonitorSetting();
      const onlineChannelRes = await onlineChannelListRequest(
        version as string
      );
      const onlineChannel = onlineChannelRes?.data?.data || []; // 已经在推流的通道
      if (onlineChannel.includes(`${terminalPhone}-${channel}`)) {
        setStartDeviceLiveLoading(false);
        setStartDeviceLiveError('');
        return true;
      } else {
        // 请求的通道没有在推流则下发指令
        const params: any = {
          phone: terminalPhone,
          jt808DeviceId,
          channelNo: channel,
          codeStream,
          type: 0 // 音视频
        };
        const res = await jt808Command9101Request(params, {
          noErrorMessage: true
        });
        setStartDeviceLiveLoading(false);
        if (res.data?.data) {
          setStartDeviceLiveError('');
          return true;
        } else {
          setStartDeviceLiveError('视频监控指令下发失败');
          return false;
        }
      }
    } catch (error) {
      setStartDeviceLiveError('视频监控指令下发失败');
      setStartDeviceLiveLoading(false);
      return false;
    }
  };

  // 初始化播放器
  const initPlayer = async () => {
    if (FlvJsPlugin.isSupported()) {
      const isSuccess = await startDeviceLive();
      if (!isSuccess) {
        // 向设备下发直播指令失败时不再初始化播放器
        return;
      }
      playerRef.current = new Player({
        ...videoConfig,
        el: videoDomRef.current,
        isLive: true,
        url: `/live/${operateJt808VersionPath(
          version as string
        )}/${terminalPhone}-${channel}`,
        plugins: [FlvJsPlugin],
        flv: {
          retryCount: 0,
          maxLatency: 5,
          targetLatency: 0
        },
        height: '100%',
        width: '100%',
        autoplay: true,
        lang: 'zh-cn',
        closeVideoClick: true,
        ignores: ['cssFullScreen', 'playbackrate', 'loading', 'start'],
        // autoplayMuted: true,
      });
      const { autocCloseVideoTime } = getMonitorSetting();
      // 视频定时停止
      if (autocCloseVideoTime) {
        timerRef.current = setTimeout(() => {
          playerRef.current?.pause();
        }, 60000 * autocCloseVideoTime);
      }
      // 暂停之后再次点击播放按钮时处理推流链接(保证链接可用)
      playerRef.current.setEventsMiddleware({
        play: async (e: any, callback: any) => {
          const { eventName } = e;
          // 如果已经在播放状态则直接播放(插件调用两次play事件的兼容处理)
          if (isPlayRef.current) {
            callback(eventName, e);
            return;
          }
          isPlayRef.current = true; // 标识处于播放状态
          const isSuccess = await startDeviceLive();
          if (isSuccess) {
            // 向设备下发直播指令成功时调用callback
            callback(eventName, e);
            const { autocCloseVideoTime } = getMonitorSetting();
            // 视频定时停止
            if (autocCloseVideoTime) {
              timerRef.current = setTimeout(() => {
                playerRef.current?.pause();
              }, 60000 * autocCloseVideoTime);
            }
          }
        }
      });
      // 监听播放器暂停事件
      playerRef.current.on(Events.PAUSE, () => {
        isPlayRef.current = false; // 标识当前不处于播放状态
        clearTimeRef(); // 清除视频定时停止定时器
      });
      // 监听播放器播放错误事件
      playerRef.current.on(Events.ERROR, () => {
        isPlayRef.current = false; // 标识当前不处于播放状态
        clearTimeRef(); // 清除视频定时停止定时器
      });
      // 开始拉流后获取下载速度信息
      playerRef.current.on(Events.LOAD_START, () => {
        getSpeedInfo();
      });
    } else {
      message.warning('浏览器不支持播放视频');
    }
  };

  useEffect(() => {
    destroyVideo();
    if (jt808DeviceId) {
      setTimeout(() => {
        initPlayer();
      }, 100)
      
    }
  }, [jt808DeviceId, channel, terminalPhone, version]);

  // 全屏
  const onFullscreen = () => {
    playerRef.current && playerRef.current.getFullscreen();
  };

  // 关闭
  const onClose = () => {
    destroyVideo();
    closeWindow && closeWindow();
  };

  if (jt808DeviceId) {
    return (
      <div className={styles.liveVideo} style={style}>
        <div className={styles.toolbar}>
          <div>{`${plateNumber} CH${channel}`}</div>
          {startDeviceLiveError && (
            <div>
              <span className="dangerText">{startDeviceLiveError}</span>
              <RedoOutlined title="重试" onClick={initPlayer} />
            </div>
          )}
          <div>
            {speed}
            <ExpandOutlined title="全屏" onClick={onFullscreen} />
            <CloseOutlined title="关闭" onClick={onClose} />
          </div>
        </div>
        <LoadingWrapper
          spinning={startDeviceLiveLoading}
          autoHeight
          tip="视频监控指令下发中..."
        >
          <div ref={videoDomRef} className={styles.video} />
        </LoadingWrapper>
      </div>
    );
  } else {
    return (
      <div className={styles.liveVideoPlaceholder} style={style}>
        <VideoCameraOutlined />
      </div>
    );
  }
};

export default LiveVideo;
