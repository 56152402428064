// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","crossMark":"crossMark_crossMark__k-6lj","leftPart":"crossMark_leftPart__PvSzO","buttonBox":"crossMark_buttonBox__LT025","roadBox":"crossMark_roadBox__c9zC-","roadItem":"crossMark_roadItem__+TQNT","rightPart":"crossMark_rightPart__8GPvl","title":"crossMark_title__grB1c","mapBox":"crossMark_mapBox__PNfpm"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"crossMark\":\"crossMark_crossMark__k-6lj\",\"leftPart\":\"crossMark_leftPart__PvSzO\",\"buttonBox\":\"crossMark_buttonBox__LT025\",\"roadBox\":\"crossMark_roadBox__c9zC-\",\"roadItem\":\"crossMark_roadItem__+TQNT\",\"rightPart\":\"crossMark_rightPart__8GPvl\",\"title\":\"crossMark_title__grB1c\",\"mapBox\":\"crossMark_mapBox__PNfpm\"}";
        // 1734082437828
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  